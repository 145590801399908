<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="['bg-white', classes.sectionPadding[globals.styles.spacing.value]]"
    >
        <div
            v-if="section.title"
            :class="[
                'container grid grid-cols-1',
                classes.contentGapHalf[globals.styles.spacing.value],
                classes.contentMarginBottomDouble[globals.styles.spacing.value],
            ]"
        >
            <h3 :class="['text-3xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.title }}
            </h3>
            <h4 v-if="section.sub_title" :class="['text-xl sm:text-2xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.sub_title }}
            </h4>
        </div>

        <div
            :class="[
                'container grid items-stretch',
                classes.layoutGrid[section.column_quantity.value],
                classes.contentGap[globals.styles.spacing.value],
            ]"
        >
            <figure
                v-for="(item, index) in section.image_gallery"
                :key="item.id"
                :class="[
                    classes.rounding[globals.styles.rounding.value],
                    section.frames && classes.imageFramed,
                ]"
                class="cursor-zoom-in"
                @click="onClick(index)"
            >
                <img
                    v-if="item.image"
                    :alt="item.image.alt"
                    :class="[
                        'size-full max-h-screen border border-gray-100 object-cover',
                        classes.rounding[globals.styles.rounding.value],
                        classes.imagePosition[item.image_position.value],
                        classes.imageAspectRatio[section.images_aspect_ratio.value],
                    ]"
                    :height="item.image.height"
                    :src="item.image.url"
                    :width="item.image.width"
                >
            </figure>
        </div>

        <VueEasyLightbox
            :imgs="imgsRef"
            :index="indexRef"
            :mask-closable="true"
            :move-disabled="true"
            :pinch-disabled="true"
            :rotate-disabled="true"
            :visible="visibleRef"
            :zoom-disabled="true"
            @hide="onHide"
        />
    </section>
</template>

<script lang="ts" setup>
    import '@/site/css/vendor/vue-easy-lightbox.css';
    import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox';

    import { useSite } from '@/site/composables/use-site.ts';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    const { show, onHide, changeIndex, visibleRef, indexRef, imgsRef } = useEasyLightbox({
        imgs: props.section.image_gallery.map((item: any) => item.image.url),
        initIndex: 0,
    });

    function onClick(index: number) {
        changeIndex(index);
        show();
    }
</script>
