import Big from 'big.js';
import { getTranslatedValue } from '@aspect/shared/plugins/i18n.ts';
import type { TaxData, TenantTaxRateData } from '@aspect/shared/types/generated';


export function differenceInPercentage(baseAmount: number, amount: number): number | null {
    if (baseAmount === 0) {
        return null;
    }

    // Amounts are in cents.
    // Percentage is returned in integer form (i.e. 500 for 5%).
    return new Big(amount)
        .div(baseAmount)
        .times(10000)
        .round()
        .toNumber();
}

export function percentageOfAmount(amount: number, percentage: number, percentageIsInteger = false): number {
    // Amount is in cents.
    if (amount === 0) {
        return 0;
    }

    // Percentage is in decimal form (i.e. 500 for 5%).
    if (percentageIsInteger) {
        return new Big(amount)
            .times(percentage)
            .div(10000)
            .round()
            .toNumber();
    }

    // Percentage is in decimal form (i.e. 0.05 for 5%).
    return new Big(amount)
        .times(percentage)
        .round()
        .toNumber();
}

export function taxAmount(amount: number, rate: number): number {
    return new Big(amount)
        .times(rate)
        .round()
        .toNumber();
}

export function totalAmount(taxRates: TenantTaxRateData[] | null | undefined, amount: number): number {
    if (!taxRates) {
        taxRates = [];
    }

    const totalAmount = taxRates.reduce((totalAmount, taxRate) => {
        return totalAmount.plus(taxAmount(amount, taxRate.rate));
    }, new Big(amount));

    // Amount is in cents.
    return totalAmount
        .round()
        .toNumber();
}

export function cumulatedRates(taxRates: TenantTaxRateData[] | null | undefined): number {
    if (!taxRates) {
        taxRates = [];
    }

    const amount = taxRates.reduce((total, taxRate) => {
        return total.plus(taxRate.rate);
    }, new Big(0));

    return amount.toNumber();
}

export function getFormattedRate(taxRates: TenantTaxRateData[] | null | undefined, tax: TaxData): string {
    if (!taxRates) {
        taxRates = [];
    }

    const taxRate = taxRates.find(rate => rate.code == tax.name);
    const rate = tax.rate ? tax.rate : taxRate?.rate;
    const amount = rate ? `(${new Big(rate).times(100).round(3).toNumber()}%)` : '';

    let taxRateName = taxRate ? getTranslatedValue(taxRate.name) : tax.name.toUpperCase();

    if (tax.translatedName) {
        taxRateName = getTranslatedValue(tax.translatedName);
    }

    return `${taxRateName} ${amount}`.trim();
}

export function subtotalAmount(taxRates: TenantTaxRateData[] | null | undefined, total: number): number {
    if (!taxRates) {
        taxRates = [];
    }

    // Total is in cents.
    return new Big(total)
        .div(cumulatedRates(taxRates) + 1)
        .round()
        .toNumber();
}
