<template>
    <DialogTitle
        :class="
            cn(
                'text-lg text-gray-950 font-medium dark:text-gray-50',
                props.class,
            )
        "
        v-bind="props"
    >
        <slot />
    </DialogTitle>
</template>

<script lang="ts" setup>
    import { DialogTitle, type DialogTitleProps } from 'radix-vue';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = defineProps<DialogTitleProps & { class?: string }>();
</script>
