<template>
    <section
        :id="section.section_id"
        :class="[
            'flex flex-col justify-center bg-cover bg-no-repeat',
            classes.bgPosition[section.background_image_position.value],
            classes.sectionPadding[globals.styles.spacing.value],
            section.background_overlay_color.value === 'darken' && 'bg-black text-gray-300',
            section.background_overlay_color.value === 'lighten' && 'bg-white text-gray-800',
            section.background_overlay_color.value === 'primary' && 'bg-tenant-500 text-tenant-text',
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlayColorValue} ${backgroundOverlayOpacityValue}), rgba(${backgroundOverlayColorValue} ${backgroundOverlayOpacityValue})), url(${section.background_image.url});`,
        ]"
    >
        <div
            v-if="section.title"
            :class="[
                'container grid grid-cols-1',
                classes.contentGapHalf[globals.styles.spacing.value],
                classes.contentMarginBottomDouble[globals.styles.spacing.value],
            ]"
        >
            <h3 :class="['text-3xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.title }}
            </h3>
            <h4 v-if="section.sub_title" :class="['text-xl sm:text-2xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.sub_title }}
            </h4>
        </div>

        <div class="container grid grid-cols-3 justify-center">
            <div />
            <div id="mc_embed_shell">
                <div id="mc_embed_signup">
                    <form
                        id="mc-embedded-subscribe-form"
                        :action="constructedUrl"
                        class="validate"
                        method="post"
                        name="mc-embedded-subscribe-form"
                        novalidate
                        target="_self"
                    >
                        <div id="mc_embed_signup_scroll" class="flex flex-col gap-6">
                            <AspectInputGroup class="mc-field-group">
                                <AspectLabel :class="textColorClasses" for="mce-EMAIL">
                                    Email Address
                                    <AspectIcon class="asterisk size-3" name="asterisk-1" />
                                </AspectLabel>

                                <AspectInput
                                    id="mce-EMAIL"
                                    :class="[
                                        'required email',
                                        textColorClasses,
                                        inputBackgroundColorClasses,
                                    ]"
                                    :wrapper-class="inputWrapperColorClasses"
                                    model-value=""
                                    name="EMAIL"
                                    required="true"
                                    type="email"
                                />
                            </AspectInputGroup>

                            <AspectInputGroup v-if="section.phone" class="mc-field-group">
                                <AspectLabel :class="textColorClasses" for="mce-PHONE">
                                    Phone Number
                                    <AspectIcon
                                        v-if="section.phone_required"
                                        class="asterisk size-3"
                                        name="asterisk-1"
                                    />
                                </AspectLabel>

                                <AspectInput
                                    id="mce-PHONE"
                                    :class="[
                                        'REQ_CSS',
                                        textColorClasses,
                                        inputBackgroundColorClasses,
                                    ]"
                                    :wrapper-class="inputWrapperColorClasses"
                                    model-value=""
                                    name="PHONE"
                                    required="true"
                                    type="text"
                                />
                            </AspectInputGroup>

                            <AspectInputGroup v-if="section.first_name" class="mc-field-group">
                                <AspectLabel :class="textColorClasses" for="mce-FNAME">
                                    First Name
                                    <AspectIcon
                                        v-if="section.first_name_required"
                                        class="asterisk size-3"
                                        name="asterisk-1"
                                    />
                                </AspectLabel>

                                <AspectInput
                                    id="mce-FNAME"
                                    :class="[
                                        'text',
                                        section.first_name_required && 'required',
                                        textColorClasses,
                                        inputBackgroundColorClasses,
                                    ]"
                                    :wrapper-class="inputWrapperColorClasses"
                                    model-value=""
                                    name="FNAME"
                                    required="true"
                                    type="text"
                                />
                            </AspectInputGroup>

                            <AspectInputGroup v-if="section.last_name" class="mc-field-group">
                                <AspectLabel :class="textColorClasses" for="mce-LNAME">
                                    Last Name
                                    <AspectIcon
                                        v-if="section.last_name_required"
                                        class="asterisk size-3"
                                        name="asterisk-1"
                                    />
                                </AspectLabel>

                                <AspectInput
                                    id="mce-LNAME"
                                    :class="[
                                        'text',
                                        section.last_name_required && 'required',
                                        textColorClasses,
                                        inputBackgroundColorClasses,
                                    ]"
                                    :wrapper-class="inputWrapperColorClasses"
                                    model-value=""
                                    name="LNAME"
                                    required="true"
                                    type="text"
                                />
                            </AspectInputGroup>

                            <SiteButton
                                id="mc-embedded-subscribe"
                                :value="t('Subscribe')"
                                class="button cursor-pointer"
                                name="subscribe"
                                tag="input"
                                type="submit"
                            />

                            <div id="mce-responses" class="clear foot">
                                <div
                                    id="mce-error-response"
                                    class="response"
                                    style="display: none;"
                                />
                                <div
                                    id="mce-success-response"
                                    class="response"
                                    style="display: none;"
                                />
                            </div>
                            <div aria-hidden="true" style="position: absolute; left: -5000px;">
                                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                                <input
                                    :name="`b_${userId}_${id}`"
                                    tabindex="-1"
                                    type="text"
                                    value=""
                                >
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { hexToRgb } from '@aspect/shared/utils/color.ts';
    import { useSite } from '@/site/composables/use-site.ts';
    import { t } from '@aspect/shared/plugins/i18n.ts';

    import SiteButton from '@/site/components/site-button.vue';
    import AspectInput from '@aspect/shared/components/aspect-input.vue';
    import AspectLabel from '@aspect/shared/components/aspect-label.vue';
    import AspectInputGroup from '@aspect/shared/components/aspect-input-group.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const props = defineProps<{
        section: any;
    }>();

    const backgroundOverlayOpacityValue = computed(() => {
        const float = props.section.background_overlay_opacity / 100;
        return Math.round(float * 100) / 100;
    });

    const backgroundOverlayColorValue = computed(() => {
        const primaryRgb = hexToRgb('--tenant-color-500');

        return {
            darken: '0, 0, 0,',
            lighten: '255, 255, 255,',
            primary: `${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b},`,
        }[props.section.background_overlay_color.value];
    });

    const { classes, globals } = useSite();

    const providedUrl = computed(() => props.section.form_url);

    const textColorClasses = computed(() => {
        return {
            darken: 'text-gray-300',
            lighten: 'text-gray-800',
            primary: 'text-tenant-text',
        }[props.section.background_overlay_color.value];
    });

    const inputWrapperColorClasses = computed(() => {
        return props.section.background_overlay_color.value === 'darken' ? 'border-gray-800 ring-gray-800' : '';
    });

    const inputBackgroundColorClasses = computed(() => {
        return props.section.background_overlay_color.value === 'darken' ? 'bg-gray-900' : '';
    });

    const subdomain = computed(() => {
        const match = providedUrl.value.match(/\/\/(.*?)\./);
        return match ? match[1] : null;
    });

    const serverName = computed(() => {
        const match = providedUrl.value.match(/\/\/.*?\.(.*?)\./);
        return match ? match[1] : null;
    });

    const userId = computed(() => {
        const match = providedUrl.value.match(/u=(.*?)&/);
        return match ? match[1] : null;
    });

    const id = computed(() => {
        const match = providedUrl.value.match(/id=(.*?)&/);
        return match ? match[1] : null;
    });

    const formId = computed(() => {
        const match = providedUrl.value.match(/f_id=(.*?)(?:&|$)/);
        return match ? match[1] : null;
    });

    const constructedUrl = computed(() => {
        return `https://${subdomain.value}.${serverName.value}.list-manage.com/subscribe/post?u=${userId.value}&id=${id.value}&f_id=${formId.value}`;
    });
</script>
