<template>
    <div
        class="scroll-mt-34 min-h-12 pb-px"
        :style="{ gridRowStart: rowStart, gridRowEnd: rowEnd }"
        :data-slot="slot.id"
    >
        <button
            :class="[
                'relative flex size-full items-center justify-between border-2 bg-white p-4 text-left transition',
                isSelected ? 'border-gray-950' : 'border-transparent',
                !isDisabled && 'hover:bg-gray-50',
            ]"
            :disabled="isDisabled"
            @click="emit('select')"
        >
            <div class="flex items-center gap-4">
                <AspectRadio
                    :model-value="activeSlot?.id"
                    :disabled="isDisabled"
                    :value="slot.id"
                    class="size-5"
                    name="selected-slot"
                    color="black"
                />

                <AspectBadge
                    v-if="showStartingAtPrice"
                    class="text-2xs shrink-0"
                    :color="isDisabled ? 'gray' : 'green'"
                >
                    {{ formatCurrency(slot.minPrice || 0) }}
                </AspectBadge>

                <span class="whitespace-nowrap text-base">
                    <template v-if="slot.restrictions?.pastRestricted">
                        {{ t('Restricted') }}
                    </template>
                    <template v-else-if="slot.restrictions?.lockedRestricted || slot.restrictions?.stateRestricted">
                        {{ t('Locked') }}
                    </template>
                    <template v-else-if="!slot.isAvailable">
                        {{ t('Coming') }}
                    </template>
                    <template v-else-if="!slot.isSelectable">
                        {{ t('Reserved') }}
                    </template>
                    <template v-else-if="slot.state === 'hidden'">
                        {{ t('Hidden') }}
                    </template>
                    <template v-else-if="slot.state === 'locked'">
                        {{ t('Locked') }}
                    </template>
                    <template v-else>
                        {{ t('Available') }}
                    </template>
                </span>
            </div>

            <div class="hidden text-right text-xs text-gray-400 sm:block">
                <AspectData type="time">
                    {{ t(':amount minutes', { amount: slot.duration.toString() }) }}
                </AspectData>
                <AspectData type="time">
                    {{ formatTime(slot.startAt) }} - {{ formatTime(slot.endAt) }}
                </AspectData>
            </div>
        </button>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';

    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { formatTime } from '@aspect/shared/utils/date.ts';
    import { formatCurrency } from '@aspect/shared/utils/number.ts';

    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

    import AspectBadge from '@aspect/shared/components/aspect-badge.vue';
    import AspectRadio from '@aspect/shared/components/aspect-radio.vue';
    import AspectData from '@aspect/shared/components/aspect-data.vue';

    import type { Dayjs } from 'dayjs';
    import type { SlotData } from '@aspect/shared/types/generated';
    import type { FormattedSlot } from '@aspect/ticket-office/components/reservation-slot-picker-agenda.vue';

    const props = defineProps<{
        slot: FormattedSlot;
        activeSlot: SlotData | null;
        durationSteps: Dayjs[];
    }>();
    const emit = defineEmits<{
        select: [];
    }>();

    const pageProps = usePageProps();

    const isSelected = computed(() => {
        return props.activeSlot?.id === props.slot.id;
    });

    const isDisabled = computed(() => {
        return !props.slot.isSelectable || !props.slot.isAvailable;
    });

    const rowStart = computed(() => {
        return props.durationSteps.findIndex(step => step.isSame(props.slot.startAt)) + 1;
    });

    const rowEnd = computed(() => {
        return props.durationSteps.findIndex(step => step.isSame(props.slot.endAt)) + 1;
    });

    const showStartingAtPrice = computed(() => {
        return pageProps.value.division.settings.ticketingShowStartingAtPrice;
    });
</script>
