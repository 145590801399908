<template>
    <Head>
        <title>{{ pageTitle }}</title>

        <!-- SEO -->
        <meta :content="pageTitle" name="title">
        <meta v-if="page.seo_description" :content="page.seo_description" name="description">
        <meta v-if="page.seo_keywords?.length" :content="page.seo_keywords.join(', ')" name="keywords">
        <meta :content="tenant.name" name="author"> <!-- Eventually will support an author for articles / posts -->
        <meta content="Aspect Systems CMS" name="generator">

        <!-- Open Graph -->
        <meta :content="page.og_title || pageTitle" property="og:title">
        <meta v-if="page.og_description || page.seo_description" :content="page.og_description || page.seo_description" property="og:description">
        <meta :content="page.permalink" property="og:url">
        <meta :content="tenant.name" property="og:site_name">
        <meta content="website" property="og:type"> <!-- Eventually will support a selection of og types in the page -->

        <!-- Open Graph Media -->
        <template v-if="page.og_image">
            <meta :content="page.og_image.url" property="og:image">
            <meta :content="page.og_image.url" property="og:image:url">
            <meta :content="page.og_image.url" property="og:image:secure_url">
            <meta :content="page.og_image.mime_type" property="og:image:type">
            <meta :content="page.og_image.width" property="og:image:width">
            <meta :content="page.og_image.height" property="og:image:height">
            <meta v-if="page.og_image.alt" :content="page.og_image.alt" property="og:image:alt">
        </template>

        <!-- Locales -->
        <!--<meta content="{ generated:currentLocale }" property="og:locale">-->
        <!-- Loops through all available locales -->
        <!--<link href="{ generated:alternateLocaleUrl }" hreflang="{ generated:alternateLocale }" rel="alternate">-->
        <!--<link href="{ generated:alternateLocaleUrl }" hreflang="{ generated:alternateLocaleNoRegionFallback }" rel="alternate"> &lt;!&ndash; fr - CA -> fr fallback &ndash;&gt;-->
        <!--<meta content="{ generated:alternateLocale }" property="og:locale:alternate">-->
        <!-- End Loop -->
    </Head>
</template>

<script lang="ts" setup>
    import { Head } from '@inertiajs/vue3';

    import type { TenantData } from '@aspect/shared/types/generated';

    const props = defineProps<{
        tenant: TenantData;
        page: Record<string, any>;
    }>();

    const pageTitle = computed(() => {
        return props.page.seo_title || `${props.page.title} - ${props.tenant.name}`;
    });

</script>
