<template>
    <Dialog v-model:open="open">
        <DialogContent
            :class="cn(
                size === 'xs' && 'sm:max-w-sm',
                size === 'sm' && 'sm:max-w-md',
                size === 'md' && 'sm:max-w-xl',
                size === 'lg' && 'sm:max-w-3xl',
                size === 'xl' && 'sm:max-w-5xl',
                size === '2xl' && 'sm:max-w-7xl',
                size === 'full' && 'sm:max-w-none',
            )"
        >
            <DialogHeader>
                <DialogTitle>
                    <slot name="title" />
                </DialogTitle>
                <DialogDescription v-if="$slots.description">
                    <slot name="description" />
                </DialogDescription>
                <template #actions>
                    <slot name="actions" />
                </template>
            </DialogHeader>

            <div class="bg-gray-100">
                <slot />
            </div>

            <DialogFooter v-if="$slots.footer">
                <slot name="footer" />
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>

<script lang="ts" setup>
    import { watch } from 'vue';

    import cn from '@aspect/shared/utils/cn.ts';

    import {
        Dialog,
        DialogContent,
        DialogFooter,
        DialogHeader,
        DialogTitle,
        DialogDescription,
    } from '@aspect/shared/components/ui/dialog';


    // PROPS & EMITS
    defineProps<{
        size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
    }>();
    const emit = defineEmits<{
        opened: [];
        closed: [];
    }>();

    // OPEN
    const open = defineModel<boolean>('open', { default: false });

    watch(open, (value) => {
        // 200ms is the duration of the leave transition.
        setTimeout(() => {
            if (value)  {
                emit('opened');
            } else {
                emit('closed');
            }
        }, 200);
    });
</script>
