<template>
    <ComboboxGroup
        :class="cn(
            'overflow-hidden p-1 text-gray-950',
            'dark:text-gray-50',
            $attrs.class ?? ''
        )"
        v-bind="props"
    >
        <ComboboxLabel v-if="heading" :class="['px-2 py-1.5 text-xs font-medium text-gray-500', 'dark:text-gray-400']">
            {{ heading }}
        </ComboboxLabel>
        <slot />
    </ComboboxGroup>
</template>

<script lang="ts" setup>
    import type { ComboboxGroupProps } from 'radix-vue';
    import { ComboboxGroup, ComboboxLabel } from 'radix-vue';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = defineProps<ComboboxGroupProps & {
        heading?: string
    }>();
</script>
