<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="section.container ? `bg-white ${classes.sectionPadding[globals.styles.spacing.value]}` : 'bg-black border-y border-black'"
    >
        <div
            :class="[
                'grid items-stretch',
                section.container ? 'container' : 'gap-px',
                classes.layoutGrid[section.column_quantity.value],
            ]"
        >
            <figure
                v-for="(item, index) in section.image_gallery"
                :key="item.id"
                class="cursor-zoom-in"
                @click="onClick(index)"
            >
                <img
                    v-if="item.image"
                    :alt="item.image.alt"
                    :class="[
                        'size-full object-cover',
                        classes.imagePosition[item.image_position.value],
                    ]"
                    :height="item.image.height"
                    :src="item.image.url"
                    :width="item.image.width"
                >
            </figure>
        </div>

        <VueEasyLightbox
            :imgs="imgsRef"
            :index="indexRef"
            :mask-closable="true"
            :move-disabled="true"
            :pinch-disabled="true"
            :rotate-disabled="true"
            :visible="visibleRef"
            :zoom-disabled="true"
            @hide="onHide"
        />
    </section>
</template>

<script lang="ts" setup>
    import '@/site/css/vendor/vue-easy-lightbox.css';
    import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox';

    import { useSite } from '@/site/composables/use-site.ts';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    const { show, onHide, changeIndex, visibleRef, indexRef, imgsRef } = useEasyLightbox({
        imgs: props.section.image_gallery.map((item: any) => item.image.url),
        initIndex: 0,
    });

    function onClick(index: number) {
        changeIndex(index);
        show();
    }
</script>
