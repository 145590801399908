<template>
    <input
        v-bind="$attrs"
        v-model="model"
        :class="cn(
            'border-gray-400 ',
            !disabled && color === 'blue' && 'text-blue-500 focus:outline-blue-500',
            !disabled && color === 'tenant' && 'text-tenant-500 focus:outline-tenant-500',
            !disabled && color === 'black' && 'text-gray-950 focus:outline-gray-950',
            disabled && 'cursor-not-allowed border-gray-300 bg-gray-100 text-gray-400',
        )"
        type="radio"
        :disabled="disabled"
    >
</template>

<script lang="ts" setup>
    import cn from '@aspect/shared/utils/cn.ts';

    defineOptions({
        inheritAttrs: true,
    });

    withDefaults(defineProps<{
        disabled?: boolean;
        color?: 'blue' | 'tenant' | 'black';
    }>(), {
        disabled: false,
        color: 'blue',
    });

    const model = defineModel<any>();
</script>
