import axios from 'axios';
import { computed, reactive } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { t } from '@aspect/shared/plugins/i18n.ts';
import useNotification from '@aspect/shared/composables/use-notification.ts';
import useHttpStatus from '@aspect/shared/composables/use-http-status.ts';

import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { InertiaForm } from '@inertiajs/vue3';


export function useAxiosForm(form?: InertiaForm<any>) {
    const { showToast, showValidationError } = useNotification();
    const { getTitle, getDescription } = useHttpStatus();

    if (!form) {
        form = reactive({
            processing: false,
            errors: {},
        });
    }

    async function query(config: AxiosRequestConfig, showNotification = true): Promise<AxiosResponse | null> {
        form.processing = true;

        const page = usePage();
        const only = config.data?.only || config.params?.only || [];
        let response: AxiosResponse | null = null;

        try {
            response = await axios.request({
                ...config,
                headers: {
                    ...(config.headers || {}),
                    'X-Expects-Json': true,
                    'X-Inertia': true,
                    ...(page.version ? { 'X-Inertia-Version': page.version } : {}),
                    ...(only.length ? {
                        'X-Inertia-Partial-Component': page.component,
                        'X-Inertia-Partial-Data': only.join(','),
                    } : {}),
                },
            });

            if (showNotification && response?.data?.notification) {
                showToast(response.data.notification);
            }

            if (form.clearErrors) {
                form.clearErrors();
            }
        } catch (error: any) {
            if (error.response?.status === 422) {
                const validationErrors = error.response.data.errors;

                Object.keys(validationErrors).forEach(key => {
                    validationErrors[key] = validationErrors[key][0];
                });

                form.errors = validationErrors;

                if (showNotification) {
                    showValidationError(validationErrors);
                }
            } else if (error.response?.data?.error) {
                showToast({
                    type: 'error',
                    title: error.response.data.error.title,
                    description: error.response.data.error.description,
                });

                throw error;
            } else {

                const title = getTitle(error.response?.status) || t('Unknown Error');
                const description = getDescription(error.response?.status) || t('Whoops, something went wrong.');

                showToast({
                    type: 'error',
                    title,
                    description,
                });

                throw error;
            }
        } finally {
            form.processing = false;
        }

        return response;
    }

    async function get(url: string, queryParams?: any): Promise<AxiosResponse | null> {
        if (!queryParams && form.data) {
            queryParams = form.data();
        }

        return query({
            url,
            method: 'get',
            params: queryParams,
        });
    }

    async function post(url: string, payload?: any, showNotification = true): Promise<AxiosResponse | null> {
        if (!payload && form.data) {
            payload = form.data();
        }

        return query({
            url,
            method: 'post',
            data: payload,
        }, showNotification);
    }

    async function put(url: string, payload?: any): Promise<AxiosResponse | null> {
        if (!payload && form.data) {
            payload = form.data();
        }

        return query({
            url,
            method: 'put',
            data: payload,
        });
    }

    async function destroy(url: string, payload?: any): Promise<AxiosResponse | null> {
        if (!payload && form.data) {
            payload = form.data();
        }

        return query({
            url,
            method: 'delete',
            data: payload,
        });
    }

    return reactive({
        processing: computed(() => form.processing),
        errors: computed(() => form.errors),
        post,
        get,
        put,
        delete: destroy,
    });
}
