<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="['bg-white', classes.sectionPadding[globals.styles.spacing.value]]"
    >
        <div
            v-if="section.title"
            :class="[
                'container grid grid-cols-1',
                classes.contentGapHalf[globals.styles.spacing.value],
                classes.contentMarginBottomDouble[globals.styles.spacing.value],
            ]"
        >
            <h3 :class="['text-3xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.title }}
            </h3>
            <h4 v-if="section.sub_title" :class="['text-xl sm:text-2xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.sub_title }}
            </h4>
        </div>

        <div
            :class="[
                'container grid items-center',
                classes.layoutGrid[section.column_quantity.value],
                classes.sectionGap[globals.styles.spacing.value],
            ]"
        >
            <div
                v-for="person in section.team"
                :key="person.id"
                :class="['flex flex-col', classes.contentGapHalf[globals.styles.spacing.value]]"
            >
                <figure :class="[classes.rounding[globals.styles.rounding.value], classes.imageAspectRatio[person.image_aspect_ratio.value]]">
                    <img
                        v-if="person.image"
                        :alt="person.image.alt"
                        :class="[
                            'size-full border border-gray-100 object-cover',
                            classes.rounding[globals.styles.rounding.value],
                        ]"
                        :height="person.image.height"
                        :src="person.image.url"
                        :width="person.image.width"
                    >
                </figure>

                <div :class="['flex flex-col', classes.contentGap[globals.styles.spacing.value]]">
                    <div>
                        <h4 class="text-2xl">
                            {{ person.first_name }} {{ person.last_name }}
                        </h4>
                        <label class="text-sm font-medium uppercase text-gray-600">
                            {{ person.title }}
                        </label>
                    </div>
                    <div
                        v-if="person.linkedin || person.facebook || person.instagram || person.twitter || person.youtube || person.website"
                        :class="['flex items-center', classes.contentGapHalf[globals.styles.spacing.value]]"
                    >
                        <a
                            v-if="person.website"
                            :href="person.website_url"
                            target="_blank"
                        >
                            <AspectIcon class="block size-6 transition hover:text-tenant-500" name="web" />
                        </a>
                        <a
                            v-if="person.linkedin"
                            :href="person.linkedin_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-linkedin"
                                name="socials/linkedin"
                            />
                        </a>
                        <a
                            v-if="person.facebook"
                            :href="person.facebook_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-facebook"
                                name="socials/facebook"
                            />
                        </a>
                        <a
                            v-if="person.instagram"
                            :href="person.instagram_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-instagram"
                                name="socials/instagram"
                            />
                        </a>
                        <a
                            v-if="person.twitter"
                            :href="person.twitter_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-x-twitter"
                                name="socials/x-twitter"
                            />
                        </a>
                        <a
                            v-if="person.youtube"
                            :href="person.youtube_url"
                            target="_blank"
                        >
                            <AspectIcon
                                :filled="true"
                                class="block size-6 transition hover:text-youtube"
                                name="socials/youtube"
                            />
                        </a>
                    </div>
                    <div :class="['flex flex-wrap justify-between', classes.contentGapHalf[globals.styles.spacing.value]]">
                        <SiteButton
                            v-if="person.mobile_phone_number"
                            :href="formattedMobilePhoneNumberUrl(person)"
                            class="flex-1"
                            size="sm"
                            tag="a"
                        >
                            <AspectIcon class="size-6 text-white" name="phone-mobile-phone" />
                        </SiteButton>
                        <SiteButton
                            v-if="person.office_phone_number"
                            :href="formattedOfficePhoneNumberUrl(person)"
                            class="flex-1"
                            size="sm"
                            tag="a"
                        >
                            <AspectIcon class="size-6 text-white" name="phone" />
                        </SiteButton>
                        <SiteButton
                            v-if="person.email"
                            :href="formattedEmailAddressUrl(person)"
                            class="flex-1"
                            size="sm"
                            tag="a"
                        >
                            <AspectIcon class="size-6 text-white" name="mail-send-envelope" />
                        </SiteButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import SiteButton from '@/site/components/site-button.vue';

    defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    const formattedMobilePhoneNumberUrl = (person) => {
        return person.mobile_phone_number && `tel:${person.mobile_phone_number.replace(/\D/g, '')}`;
    };

    const formattedOfficePhoneNumberUrl = (person) => {
        const formattedNumber = person.office_phone_number && person.office_phone_number.replace(/\D/g, '');
        const formattedExtension = person.office_extension && person.office_extension.replace(/\D/g, '');

        if (person.office_extension) {
            if (person.extension_behaviour === 'pause') {
                return `tel:${formattedNumber},${formattedExtension}`;
            }
            return `tel:${formattedNumber};${formattedExtension}`;
        }

        return `tel:${formattedNumber}`;
    };

    const formattedEmailAddressUrl = (person) => {
        return person.email && `mailto:${person.first_name} ${person.last_name} <${person.email}>`;
    };
</script>
