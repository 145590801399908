const caching = {};

export default function useCaching() {
    function get(key: string) {
        return caching[key];
    }

    function set(key: string, value: any) {
        caching[key] = value;
    }

    return {
        get,
        set,
    };
}
