<template>
    <Transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0 translate-y-8"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="ease-in duration-300"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-8"
    >
        <div
            v-if="running"
            :class="[
                'pointer-events-none fixed inset-x-0 z-10 flex items-center',
                'transition-all duration-300',
                'bottom-0 justify-center lg:top-auto lg:justify-end',
            ]"
        >
            <div
                :class="[
                    'flex items-center rounded shadow-lg transition',
                    'gap-2 p-2 lg:gap-4 lg:p-4',
                    'mb-4 lg:mb-8 lg:mr-8',
                    timer >= 120 && 'bg-gray-950 text-gray-50',
                    timer >= 60 && timer <= 120 && 'animate-bounce-zoom bg-amber-500 text-amber-50 shadow-amber-500/50',
                    timer <= 60 && 'animate-bounce-zoom bg-red-500 text-red-50 shadow-red-500/50',
                ]"
            >
                <AspectIcon class="size-6 lg:size-8" name="stopwatch" />
                <div class="flex justify-center gap-2 lg:flex-col">
                    <AspectLabel
                        :class="[
                            timer >= 120 && 'text-gray-50',
                            timer >= 60 && timer <= 120 && 'text-amber-50',
                            timer <= 60 && 'text-red-50'
                        ]"
                    >
                        {{ t('Time left to book') }}
                    </AspectLabel>
                    <time class="text-lg tabular-nums lg:text-2xl">
                        {{ formattedTimer }}
                    </time>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
    import { computed, ref, watch } from 'vue';

    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { date, formatSeconds } from '@aspect/shared/utils/date.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectLabel from '@aspect/shared/components/aspect-label.vue';

    import { Dayjs } from 'dayjs';

    const props = defineProps<{
        running: boolean;
        limit: number;
    }>();
    const emit = defineEmits<{
        started: [];
        ended: [];
    }>();

    const timer = ref(0);
    const stopAt = ref<Dayjs | null>(null);
    const interval = ref();

    watch(() => props.running, (value) => {
        if (value) {
            onStartTimer();
        } else {
            stopTimer();
        }
    }, { immediate : true });

    function onStartTimer() {
        stopAt.value = date().add(props.limit, 'seconds');
        timer.value = props.limit;

        interval.value = setInterval(() => {
            timer.value--;

            if (timer.value === 0 || stopAt.value?.isBefore(date())) {
                onEndTimer();
            }
        }, 1000);

        emit('started');
    }

    function onEndTimer() {
        stopTimer();
        emit('ended');
    }

    function stopTimer() {
        if (!interval.value) {
            return;
        }

        clearInterval(interval.value);
    }

    const formattedTimer = computed(() => {
        return formatSeconds(timer.value, false);
    });
</script>
