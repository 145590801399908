<template>
    <div :class="cn('animate-pulse rounded bg-gray-200 dark:bg-gray-800', props.class)" />
</template>

<script setup lang="ts">
    import type { HTMLAttributes } from 'vue';
    import cn from '@aspect/shared/utils/cn.ts';

    interface SkeletonProps {
        class?: HTMLAttributes['class']
    }

    const props = defineProps<SkeletonProps>();
</script>
