import { computed } from 'vue';
import { isNumber } from 'lodash-es';

import type { SlotData } from '@aspect/shared/types/generated';
import type { Availability } from '@aspect/shared/types/global';

export function getAvailability(totalCapacity: number | null | undefined, remainingCapacity: number | null | undefined): Availability {
    if (remainingCapacity === null || remainingCapacity === undefined || !totalCapacity) {
        return 'available';
    }

    if (remainingCapacity <= 0) {
        return 'full';
    }

    if (remainingCapacity / totalCapacity < 0.25) {
        return 'almost-full';
    }

    return 'available';
}

export function useSlots(slots: SlotData[]) {
    const totalCapacity = computed(() => {
        return slots.reduce((capacity: number | null, slot: SlotData) => {
            if (!isNumber(capacity) || !isNumber(slot.capacity)) {
                return null;
            }

            return capacity + slot.capacity;
        }, 0);
    });

    const remainingCapacity = computed(() => {
        return slots.reduce((capacity: number | null, slot: SlotData) => {
            if (!isNumber(capacity) || !isNumber(slot.remainingCapacity)) {
                return null;
            }

            return capacity + slot.remainingCapacity;
        }, 0);
    });

    const minPrice = computed(() => {
        return slots.reduce((minPrice: number | null, slot: SlotData) => {
            if (!isNumber(slot.minPrice)) {
                return minPrice;
            }

            if (!isNumber(minPrice)) {
                return slot.minPrice;
            }

            return Math.min(minPrice, slot.minPrice);
        }, null);
    });

    const availability = computed<Availability>(() => {
        if (!slots.length) {
            return 'closed';
        }

        if (slots.every(slot => slot.isAvailable === false)) {
            return 'coming';
        }

        if (slots.every(slot => slot.isSelectable === false)) {
            return 'restricted';
        }

        return getAvailability(totalCapacity.value, remainingCapacity.value);
    });

    return {
        totalCapacity,
        remainingCapacity,
        minPrice,
        availability,
    };
}
