<template>
    <ComboboxItem
        :class="cn(
            'relative flex cursor-default select-none items-center rounded p-2 text-sm outline-none transition-colors',
            !props.disabled && 'data-[highlighted]:bg-gray-100 data-[highlighted]:text-gray-900',
            !props.disabled && 'dark:data-[highlighted]:bg-gray-800 dark:data-[highlighted]:text-gray-50',
            'data-[disabled]:opacity-50 data-[disabled]:active:pointer-events-none',
            $attrs.class ?? ''
        )"
        v-bind="{ ...props, ...emitsAsProps }"
        @select.prevent
    >
        <slot />
    </ComboboxItem>
</template>

<script lang="ts" setup>
    import { ComboboxItem, useEmitAsProps } from 'radix-vue';

    import cn from '@aspect/shared/utils/cn.ts';

    import type { ComboboxItemEmits, ComboboxItemProps } from 'radix-vue';

    const props = defineProps<ComboboxItemProps>();
    const emits = defineEmits<ComboboxItemEmits>();

    const emitsAsProps = useEmitAsProps(emits);
</script>
