<template>
    <ComboboxContent :class="cn('max-h-[300px] overflow-y-auto overflow-x-hidden', $attrs.class ?? '')" v-bind="forwarded">
        <div role="presentation">
            <slot />
        </div>
    </ComboboxContent>
</template>

<script lang="ts" setup>
    import type { ComboboxContentEmits, ComboboxContentProps } from 'radix-vue';
    import { ComboboxContent, useForwardPropsEmits } from 'radix-vue';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = defineProps<ComboboxContentProps>();
    const emits = defineEmits<ComboboxContentEmits>();

    const forwarded = useForwardPropsEmits(props, emits);
</script>
