<template>
    <div class="group relative">
        <div class="-mb-px flex flex-col">
            <div v-if="!slots.length" class="p-4 text-center italic">
                {{ t('No available slots') }}
            </div>

            <ReservationSlotPickerList
                v-if="division.settings.entryType === 'person'"
                :slots="slots"
                :active-slot="activeSlot"
                @select="value => activeSlot = value"
            />
            <ReservationSlotPickerAgenda
                v-else-if="division.settings.entryType === 'time'"
                :slots="slots"
                :active-slot="activeSlot"
                :selected-day="selectedDay"
                @select="value => activeSlot = value"
            />
        </div>

        <div
            v-if="division.settings.entryType === 'person'"
            :class="[
                'sticky inset-x-0 z-10 flex w-full justify-center border-t border-gray-200 bg-white/70 p-4 text-sm backdrop-blur transition-all duration-300 md:justify-between',
                activeSlot ? 'bottom-22' : 'bottom-0',
            ]"
        >
            <AspectData class="hidden text-sm md:flex">
                <template v-if="totalAvailableCount !== null">
                    {{ tc(':amount available spot|:amount available spots', totalAvailableCount, { amount: totalAvailableCount.toString() }) }}
                </template>
                <template v-else>
                    {{ t('Unlimited spots available') }}
                </template>
            </AspectData>

            <div class="flex items-center gap-2">
                <AspectToggle v-model="showUnavailableSlots" color="black" />
                {{ t('Show unavailable slots') }}
            </div>
        </div>

        <Transition
            enter-active-class="duration-300"
            enter-from-class="opacity-0 translate-y-4"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="duration-300"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-4"
        >
            <div v-if="activeSlot" class="sticky bottom-0 z-10 flex items-center">
                <AspectButtonAttached
                    class="grow rounded-b"
                    size="xl"
                    :color-hover-only="false"
                    color="black"
                    :disabled="isDisabled"
                    :disabled-reason="disabledReason"
                    :loading="offeringStepLoading"
                    @click="emit('select')"
                >
                    <AspectIcon name="calendar-check" class="animate-blink animate-delay-[5s] size-5" />
                    <span v-if="slotAvailableCount !== null" class="animate-blink animate-delay-[5s]">
                        {{ tc('Reserve up to :amount spot|Reserve up to :amount spots', slotAvailableCount, { amount: slotAvailableCount.toString() }) }}
                    </span>
                    <span v-else class="animate-blink animate-delay-[5s]">
                        {{ t('Reserve spots') }}
                    </span>
                </AspectButtonAttached>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
    import { computed, watch } from 'vue';

    import { t, tc } from '@aspect/shared/plugins/i18n.ts';
    import { useSlots } from '@aspect/ticket-office/composables/use-slots.ts';
    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

    import AspectToggle from '@aspect/shared/components/aspect-toggle.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectData from '@aspect/shared/components/aspect-data.vue';

    import ReservationSlotPickerList from '@aspect/ticket-office/components/reservation-slot-picker-list.vue';
    import ReservationSlotPickerAgenda from '@aspect/ticket-office/components/reservation-slot-picker-agenda.vue';

    import type { Dayjs } from 'dayjs';
    import type { SlotData } from '@aspect/shared/types/generated';


    // PROPS & EMIT
    const props = defineProps<{
        slots: SlotData[];
        offeringStepLoading: boolean;
        selectedDay: Dayjs;
    }>();
    const emit = defineEmits<{
        select: [];
    }>();

    const pageProps = usePageProps();


    // DIVISION
    const division = computed(() => pageProps.value.division);


    // SHOW UNAVAILABLE SLOTS
    const showUnavailableSlots = defineModel<boolean>('showUnavailableSlots', { required: true });


    // ACTIVE SLOT
    const activeSlot = defineModel<SlotData | null>('activeSlot', { required: true });

    watch(() => props.slots, () => {
        if (activeSlot.value) {
            activeSlot.value = props.slots.find(slot => slot.id === activeSlot.value?.id) || null;
        }
    });


    // IS DISABLED
    const isDisabled = computed(() => {
        return !activeSlot.value || activeSlot.value.isSelectable === false;
    });

    const disabledReason = computed(() => {
        if (activeSlot.value?.restrictions?.lockedRestricted) {
            return t('Time slot locked');
        }

        return '';
    });


    // TOTAL AVAILABLE ENTRIES
    const totalAvailableCount = computed(() => {
        const { remainingCapacity } = useSlots(props.slots);

        return remainingCapacity.value;
    });


    // TOTAL AVAILABLE ENTRIES
    const slotAvailableCount = computed(() => {
        if (division.value.settings.entryType === 'time') {
            return null;
        }

        return activeSlot.value?.remainingCapacity || null;
    });
</script>
