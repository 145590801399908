<template>
    <AspectButtonAttached
        :class="[
            'flex-1 transition duration-300',
            !disabled && 'hover:z-10',
            disabled && 'cursor-not-allowed',
        ]"
        size="sm"
        tabindex="-1"
        :disabled="disabled"
        :disabled-reason="disabledReason"
        @click="onClick"
    >
        <slot />
    </AspectButtonAttached>
</template>

<script lang="ts" setup>
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';

    const props = withDefaults(defineProps<{
        disabled?: boolean;
        disabledReason?: string;
    }>(), {
        disabled: false,
    });
    const emit = defineEmits<{
        click: [];
    }>();

    function onClick() {
        if (props.disabled) {
            return;
        }

        emit('click');
    }
</script>
