<template>
    <div
        v-if="visible"
        :class="[
            'rounded border p-3',
            type === 'info' && 'border-tenant-100 bg-tenant-50',
            type === 'success' && 'border-green-200 bg-green-100',
            type === 'warning' && 'border-yellow-100 bg-yellow-50',
            type === 'error' && 'border-red-100 bg-red-50',
        ]"
    >
        <div class="flex">
            <div class="shrink-0">
                <InfoIcon v-if="type === 'info'" class="text-tenant-400 size-5" />
                <CheckCircle2Icon v-if="type === 'success'" class="size-5 text-green-500" />
                <AlertTriangleIcon v-if="type === 'warning'" class="size-5 text-yellow-500" />
                <XCircleIcon v-if="type === 'error'" class="size-5 text-red-400" />
            </div>
            <div class="ml-3">
                <p
                    :class="[
                        'text-xs',
                        type === 'info' && 'text-tenant-700',
                        type === 'success' && 'text-green-800',
                        type === 'warning' && 'text-yellow-700',
                        type === 'error' && 'text-red-700',
                    ]"
                >
                    <slot />
                </p>
            </div>
            <div
                v-if="closable"
                class="ml-auto pl-3"
            >
                <div class="-m-1.5">
                    <button
                        :class="[
                            'inline-flex rounded-md p-1.5 transition',
                            'focus-visible:ring-2',
                            type === 'info' && 'bg-tenant-50 text-tenant-500 hover:bg-tenant-100 focus-visible:ring-tenant-500',
                            type === 'success' && 'bg-green-100 text-green-500 hover:bg-green-200 focus-visible:ring-green-500',
                            type === 'warning' && 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus-visible:ring-yellow-500',
                            type === 'error' && 'bg-red-50 text-red-500 hover:bg-red-100 focus-visible:ring-red-500',
                        ]"
                        type="button"
                        @click="visible = false"
                    >
                        <XIcon class="size-5" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import { InfoIcon, CheckCircle2Icon, XCircleIcon, AlertTriangleIcon, XIcon } from 'lucide-vue-next';

    withDefaults(
        defineProps<{
            closable?: boolean;
            type: 'success' | 'error' | 'warning' | 'info';
        }>(),
        {
            closable: false,
        },
    );

    const visible = ref(true);
</script>
