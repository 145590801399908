<template>
    <div
        :class="hidden ? 'h-0 w-0' : 'flex items-center px-3'"
        cmdk-input-wrapper
    >
        <template v-if="!hidden">
            <AspectSpinner v-if="loading" class="mr-3 size-3.5 shrink-0 text-gray-500" />
            <AspectIcon v-else class="mr-3 size-3.5 shrink-0 text-gray-500" name="magnifying-glass" />
        </template>
        <ComboboxInput
            :class="cn(
                'flex h-9 w-full rounded-md bg-transparent py-3 px-0 text-sm outline-none ring-0 border-none focus:ring-0',
                'placeholder:text-gray-500',
                'disabled:cursor-not-allowed disabled:opacity-50',
                'dark:placeholder:text-gray-400',
                hidden && 'h-0 w-0',
                props.class,
            )"
            v-bind="{ ...forwardedProps, ...$attrs }"
            @keyup.enter="event => event.target.blur()"
        />
    </div>
</template>

<script lang="ts" setup>
    import { type HTMLAttributes, computed } from 'vue';
    import { ComboboxInput, type ComboboxInputProps, useForwardProps } from 'radix-vue';

    import cn from '@aspect/shared/utils/cn.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectSpinner from '@aspect/shared/components/aspect-spinner.vue';

    defineOptions({
        inheritAttrs: false,
    });

    const props = withDefaults(defineProps<ComboboxInputProps & {
        class?: HTMLAttributes['class']
        hidden?: boolean;
        loading?: boolean;
    }>(), {
        hidden: false,
        loading: false,
        autoFocus: true,
    });

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, ...delegated } = props;

        return delegated;
    });

    const forwardedProps = useForwardProps(delegatedProps);
</script>

