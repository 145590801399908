<template>
    <section :id="section.section_id" :class="classes.sectionPadding[globals.styles.spacing.value]">
        <div v-if="form.processing || loading" class="flex items-center justify-center">
            <AspectSpinner class="size-12" />
        </div>

        <div class="container">
            <PublicBooking
                v-if="paymentMethods && reservation && termsAndConditions && division"
                :division="division"
                :payment-methods="paymentMethods"
                :promo-code="promoCode"
                :redirect-on-success="false"
                :reservation="reservation"
                :schedules="schedules"
                :terms-and-conditions="termsAndConditions"
                @loading="value => loading = value"
            />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useRoute } from '@aspect/shared/composables/use-route.ts';
    import { useAxiosForm } from '@aspect/shared/composables/use-axios-form.ts';

    import { useSite } from '@/site/composables/use-site.ts';

    import AspectSpinner from '@aspect/shared/components/aspect-spinner.vue';

    import PublicBooking from '@aspect/ticket-office/components/public-booking.vue';

    import type {
        CreateReservationData,
        DivisionData,
        PaymentMethodsData,
        ScheduleData,
        TranslatableData,
    } from '@aspect/shared/types/generated';

    const props = defineProps<{
        section: any;
    }>();

    const { consumerRoute } = useRoute();

    const { classes, globals } = useSite();

    const searchParams = new URLSearchParams(window.location.search);

    // FORM
    const promoCode = searchParams.get('promo');
    const reservation = ref<CreateReservationData>();
    const paymentMethods = ref<PaymentMethodsData>();
    const termsAndConditions = ref<TranslatableData>();
    const division = ref<DivisionData>();
    const schedules = ref<ScheduleData[]>([]);

    const form = useForm<{ schedules: string[] }>({ schedules: props.section.schedules.map(schedule => schedule.schedule_id) });
    const axiosForm = useAxiosForm(form);

    onMounted(async () => {
        const response = await axiosForm.get(consumerRoute('/{division}/reservations/create', {
            division: props.section.division_id,
        }));

        if (response) {
            reservation.value = response.data.reservation;
            paymentMethods.value = response.data.paymentMethods;
            termsAndConditions.value = response.data.termsAndConditions;
            schedules.value = response.data.schedules;
            division.value = response.data.division;
        }
    });


    // LOADING
    const loading = ref(true);
</script>
