<template>
    <button
        :class="cn(
            'grow rounded p-1.5 font-semibold',
            'md:p-3',
            isDisabled ? 'text-gray-500 cursor-not-allowed' : 'text-gray-700 hover:bg-gray-200',
            day.isSame(date(), 'day') && 'bg-gray-100',
            isSelected && 'bg-gray-950 text-white hover:bg-gray-900',
        )"
        :disabled="isDisabled"
        class="flex flex-col items-center"
        v-bind="$attrs"
    >
        <div
            :class="[
                'text-sm md:text-2xl',
                !isLoading && availability === 'closed' && 'line-through',
            ]"
        >
            {{ format(day, 'D') }}
        </div>

        <AspectSkeleton v-if="isLoading" :class="['mt-1 h-3 w-12', 'hidden lg:block']" />

        <div v-else :class="['text-2xs uppercase', 'hidden lg:block']">
            <template v-if="isPast">
                {{ t('Past') }}
            </template>
            <template v-else-if="availability === 'closed'">
                {{ t('Closed') }}
            </template>
            <template v-else-if="availability === 'coming'">
                {{ t('Coming') }}
            </template>
            <template v-else-if="availability === 'full'">
                {{ t('Full') }}
            </template>
            <template v-else-if="availability === 'restricted' && !isPast">
                {{ t('Restricted') }}
            </template>
            <template v-else>
                {{ t('Open') }}
            </template>
        </div>
    </button>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';

    import cn from '@aspect/shared/utils/cn.ts';
    import { date, format } from '@aspect/shared/utils/date.ts';
    import { t } from '@aspect/shared/plugins/i18n.ts';

    import AspectSkeleton from '@aspect/shared/components/aspect-skeleton.vue';

    import type { Dayjs } from 'dayjs';
    import type { Availability } from '@aspect/shared/types/global';

    const props = defineProps<{
        day: Dayjs;
        availability: Availability;
        isDisabled: boolean;
        isSelected: boolean;
        isLoading: boolean;
    }>();

    const isPast = computed(() => {
        return props.day.isBefore(date(), 'day');
    });
</script>
