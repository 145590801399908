<template>
    <ComboboxRoot
        :class="cn(
            'flex h-full w-full flex-col overflow-hidden rounded-md bg-white text-gray-950',
            'dark:bg-gray-950 dark:text-gray-50',
            $attrs.class ?? ''
        )"
        v-bind="forwarded"
    >
        <slot />
    </ComboboxRoot>
</template>

<script lang="ts" setup>
    import type { ComboboxRootEmits, ComboboxRootProps } from 'radix-vue';
    import { ComboboxRoot, useForwardPropsEmits } from 'radix-vue';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = withDefaults(defineProps<ComboboxRootProps>(), {
        modelValue: '',
        open: true,
    });
    const emits = defineEmits<ComboboxRootEmits>();

    const forwarded = useForwardPropsEmits(props, emits);
</script>
