<!-- eslint-disable vue/no-v-html -->
<template>
    <AspectDialog
        v-model:open="open"
        size="lg"
    >
        <template #title>
            {{ t('Terms and Conditions') }}
        </template>

        <div class="tiptap flex p-6">
            <div v-html="terms" />
        </div>

        <template #footer>
            <div class="flex justify-end">
                <AspectButtonAttached
                    class="-mt-px border"
                    @click="onCancel"
                >
                    {{ t('Cancel') }}
                </AspectButtonAttached>
                <AspectButtonAttached
                    color="black"
                    class="-ml-px -mt-px border"
                    :color-hover-only="false"
                    @click="onAccept"
                >
                    {{ t('I Agree') }}
                </AspectButtonAttached>
            </div>
        </template>
    </AspectDialog>
</template>

<script lang="ts" setup>
    import { t } from '@aspect/shared/plugins/i18n.ts';

    import AspectDialog from '@aspect/shared/components/aspect-dialog.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';

    defineProps<{
        terms: string,
    }>();
    const emit = defineEmits<{
        accept: [],
    }>();

    const open = defineModel<boolean>('open', { required: true });

    function onCancel() {
        open.value = false;
    }

    function onAccept() {
        open.value = false;
        emit('accept');
    }
</script>
