import type { MemberData, MemberMembershipData, MemberOrganizationData } from '@aspect/shared/types/generated';

export function useMemberships() {
    function getCustomer(member: MemberData, membership: MemberMembershipData) {
        if (membership.customerId === member.id) {
            return member;
        }

        return member.organizations.find(organization => organization.id === membership.customerId) as MemberOrganizationData;
    }

    function getMemberNumber(member: MemberData, membership: MemberMembershipData) {
        const customer = getCustomer(member, membership);
        const showCustomerCode = membership.customerId !== member.id;

        return (showCustomerCode ? `${member.code}-` : '') + customer.memberNumber;
    }

    function getMemberSince(member: MemberData, membership: MemberMembershipData) {
        const customer = getCustomer(member, membership);

        return customer.memberSince;
    }

    return {
        getCustomer,
        getMemberNumber,
        getMemberSince,
    };
}
