<template>
    <component
        :is="components[kebabCase(section.type)]"
        v-for="section in page.sections"
        :key="section.id"
        :section="section"
        v-bind="$attrs"
    />
</template>

<script lang="ts" setup>
    import { kebabCase } from 'lodash-es';

    import type { DefineComponent } from 'vue';

    defineProps<{
        page: {
            sections: any[];
            [x: string]: any;
        };
    }>();

    const components = {};
    const files = import.meta.glob('./sections/*.vue', { eager: true });

    for (const path in files) {
        const name = path.replace(/^.*\//, '').replace(/\.vue$/, '');
        components[name] = (files[path] as DefineComponent).default;
    }
</script>
