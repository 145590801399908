export const hexToRgb = (hex: string) => {
    if (hex.startsWith('--')) {
        hex = getComputedStyle(document.documentElement).getPropertyValue(hex);
    }

    // Remove '#' if present
    hex = hex.replace('#', '');

    return {
        r: parseInt(hex.substring(0, 2), 16),
        g: parseInt(hex.substring(2, 4), 16),
        b: parseInt(hex.substring(4, 6), 16),
    };
};
