import { ref, watch, computed } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { orderBy } from 'lodash-es';

import { date } from '@aspect/shared/utils/date.ts';
import { useAxiosForm } from '@aspect/shared/composables/use-axios-form.ts';
import { useRoute } from '@aspect/shared/composables/use-route.ts';
import { useTicketOfficeStore } from '@aspect/ticket-office/stores/use-ticket-office-store.ts';
import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

import type { Ref } from 'vue';
import type { Offering, OfferingSlot } from '@aspect/shared/types/global';
import type {
    BundleData,
    ConsumerOfferingRequest,
    CreateEntryData,
    DivisionData,
    OfferingSlotData,
    SlotData,
    TicketData,
} from '@aspect/shared/types/generated';

export function useReservationOfferings({ division, selectedSlot, slotsByDay }: {
    division: Ref<DivisionData>,
    selectedSlot: Ref<SlotData | null>,
    slotsByDay: Ref<Record<string, SlotData[]>>,
}) {
    const store = useTicketOfficeStore();
    const pageProps = usePageProps();
    const { consumerRoute } = useRoute();


    // ENTRIES
    const entries = ref<CreateEntryData[]>([]);

    const offeringsSelected = computed(() => {
        return !!entries.value.length;
    });

    watch(() => selectedSlot.value?.id, () => {
        entries.value = [];
        getSlotOfferings();
    });


    // ENTRIES VALIDATION
    function entryIsValid(entry: CreateEntryData, index: number) {
        if (entry.inviteType === 'member') {
            return !!entry.customerId;
        }

        if (entry.entryableType === 'bundle') {
            return (entry.subEntries || []).every((subEntry, subIndex) => entryIsValid(subEntry, subIndex));
        }

        if (!entry.inviteType) {
            return false;
        }

        if (index !== 0 && !store.memberBooking) {
            return true;
        }

        if (!pageProps.value.division.settings.ticketingEntryName) {
            return true;
        }

        if (entry.inviteType === 'guest') {
            return !!(entry.firstName || '').trim() && !!(entry.lastName || '').trim();
        }

        return false;
    }

    const allEntriesValid = computed(() => {
        return entries.value.every((entry, index) => entryIsValid(entry, index));
    });

    const ticketsCount = computed(() => {
        return entries.value.reduce((total, entry) => {
            return entry.entryableType === 'bundle'
                ? total + (entry.subEntries?.length || 1)
                : total + 1;
        }, 0);
    });


    // SLOT OFFERINGS
    const slotOfferings = ref<OfferingSlotData | null>(null);
    const slotOfferingsLoading = ref(false);

    async function getSlotOfferings() {
        if (!selectedSlot.value) {
            slotOfferings.value = null;
            return;
        }

        slotOfferingsLoading.value = true;

        const form = useForm<ConsumerOfferingRequest>({
            slotId: selectedSlot.value.id,
            customerMembershipId: store.selectedMembership?.id || null,
        });

        const response = await useAxiosForm(form).get(consumerRoute('/{division}/offerings', {
            division: division.value.id,
        }));

        if (response) {
            slotOfferings.value = response.data;
            slotOfferingsLoading.value = false;
        }
    }


    // OFFERINGS
    const offerings = computed<Offering[]>(() => {
        if (!selectedSlot.value || !slotOfferings.value) {
            return [];
        }

        if (selectedSlot.value.id !== slotOfferings.value.slotId) {
            return [];
        }

        const slot = selectedSlot.value as SlotData;

        const ticketOfferings: Offering[] = slotOfferings.value.tickets.map((ticket: TicketData) => {
            const periodTicket = ticket.periodTickets?.find(periodTicket => periodTicket.periodId === slot.periodId);

            return {
                ...ticket,
                _type: 'ticket',
                price: periodTicket?.adjustedPrice ?? ticket.price,
                slots: getTicketSlots({
                    ticketId: ticket.id,
                    slotCount: ticket.slotCount,
                }),
            };
        });


        const bundleOfferings: Offering[] = slotOfferings.value.bundles.map((bundle: BundleData) => {
            const bundleTickets = bundle.tickets || [];

            let slots = bundleTickets.reduce((slots: OfferingSlot[], bundleTicket) => {
                const ticketSlots = getTicketSlots({
                    ticketId: bundleTicket.ticketId,
                    bundleId: bundle.id,
                    slotCount: bundleTicket.slotCount,
                });

                return [...slots, ...ticketSlots];
            }, []);

            // Exception for empty bundles (i.e. donations)...
            if (bundleTickets.length === 0 && selectedSlot.value) {
                slots = [{
                    id: selectedSlot.value.id,
                    ticketId: null,
                    capacity: null,
                    limit: null,
                }];
            }

            return {
                ...bundle,
                _type: 'bundle',
                slots,
            };
        });

        return [...ticketOfferings, ...bundleOfferings].filter(offering => {
            return offering.slots.some(slot => slot.capacity === null || slot.capacity > 0);
        });
    });


    // REMAINING CAPACITY
    function getSlotCapacity(slot: SlotData | null) {
        if (!slot) {
            return null;
        }

        return slot.restrictions?.limitedCapacity ?? slot.remainingCapacity ?? null;
    }

    function getRemainingCapacity(slot: SlotData, offeringId: string): number | null {
        const slotCapacity = getSlotCapacity(slot);

        if (slotCapacity === null) {
            return null;
        }

        const selectedTickets = entries.value
            .filter(entry => entry.entryableId !== offeringId)
            .reduce((tickets: CreateEntryData[], entry) => {
                const currentTickets = entry.entryableType === 'bundle' ? entry.subEntries || [] : [entry];
                return [...tickets, ...currentTickets];
            }, [])
            .filter(entry => entry.slotId === slot.id);

        const remainingCapacity = slotCapacity - selectedTickets.length;

        return remainingCapacity > 0 ? remainingCapacity : 0;
    }


    // TICKET SLOTS
    function getTicketSlots({ ticketId, bundleId, slotCount }: { ticketId: string, bundleId?: string, slotCount: number }): OfferingSlot[] {
        if (!selectedSlot.value) {
            return [];
        }

        const slots: OfferingSlot[] = [];
        let currentSlot: SlotData | null = selectedSlot.value;

        for (let i = 0; i < slotCount; i++) {
            const slotCapacity = getSlotCapacity(currentSlot);

            // Stop if capacity is 0 or no more slots available
            if (slotCapacity === 0 || !currentSlot) {
                break;
            }

            slots.push({
                id: currentSlot.id,
                ticketId,
                capacity: slotCapacity,
                limit: getRemainingCapacity(currentSlot, bundleId || ticketId),
            });

            currentSlot = getNextSlot(currentSlot);
        }

        return slots;
    }

    function getSameDaySlots(slot: SlotData): SlotData[] {
        const currentSlotDate = date(slot.dateTime).format('YYYY-MM-DD');

        return orderBy(slotsByDay.value[currentSlotDate] || [], ['dateTime'], ['asc'])
            .filter(daySlot => daySlot.scheduleId === slot.scheduleId);
    }

    function getNextSlot(slot: SlotData): SlotData | null {
        const daySlots = getSameDaySlots(slot);
        const currentSlotIndex = daySlots.findIndex(daySlot => daySlot.id === slot.id);

        if (currentSlotIndex === -1 || currentSlotIndex + 1 >= daySlots.length) {
            return null;
        }

        const nextSlot = daySlots[currentSlotIndex + 1];
        const nextSlotDate = date(nextSlot.dateTime);
        const allowedNextSlotTime = date(slot.dateTime).add(slot.duration, 'minute');

        return nextSlotDate.isAfter(allowedNextSlotTime) ? null : nextSlot;
    }


    // OFFERING SLOTS
    const selectedOfferingSlots = computed<SlotData[]>(() => {
        if (!selectedSlot.value) {
            return [];
        }

        const slots = getSameDaySlots(selectedSlot.value);

        const tickets = entries.value.reduce((tickets: CreateEntryData[], entry) => {
            if (entry.entryableType === 'bundle') {
                tickets.push(...(entry.subEntries || []));
            } else {
                tickets.push(entry);
            }

            return tickets;
        }, []);

        return slots.reduce((slots: SlotData[], slot) => {
            const isSelected = tickets.some(entry => entry.slotId === slot.id);
            const alreadyAdded = slots.some(s => s.id === slot.id);

            if (isSelected && !alreadyAdded) {
                return [...slots, slot];
            }

            return slots;
        }, []);
    });

    return {
        slotOfferings,
        slotOfferingsLoading,

        offerings,
        offeringsSelected,
        selectedOfferingSlots,

        entries,
        ticketsCount,
        allEntriesValid,
    };
}
