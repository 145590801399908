<template>
    <section
        :id="section.section_id"
        :class="[
            'bg-black bg-cover bg-no-repeat text-gray-300',
            classes.bgPosition[section.background_image_position.value],
            classes.sectionPadding[globals.styles.spacing.value],
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${section.background_image.url});`,
            !section.background_image && `background-image: linear-gradient(to right, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75))`
        ]"
    >
        <div
            v-if="section.title"
            :class="[
                'container grid grid-cols-1',
                classes.contentGapHalf[globals.styles.spacing.value],
                classes.contentMarginBottomDouble[globals.styles.spacing.value],
            ]"
        >
            <h3 :class="['text-3xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.title }}
            </h3>
            <h4 v-if="section.sub_title" :class="['text-xl sm:text-2xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.sub_title }}
            </h4>
        </div>

        <div class="container">
            <iframe
                :allow="providerAllowances"
                :class="[ 'mx-auto aspect-wide max-w-prose', classes.rounding[globals.styles.rounding.value] ]"
                :src="formattedUrl"
                :title="section.description"
                allowfullscreen
                height="100%"
                width="100%"
            />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    const formattedUrl = computed(() => {
        if (props.section.provider.value === 'youtube') {
            const youtube_id = props.section.url.split('v=')[1];
            return `https://www.youtube.com/embed/${youtube_id}`;
        }
        if (props.section.provider.value === 'vimeo') {
            const vimeo_id = props.section.url.split('vimeo.com/')[1];
            return `https://player.vimeo.com/video/${vimeo_id}`;
        }
        return '';
    });

    const providerAllowances = computed(() => {
        if (props.section.provider === 'youtube') {
            return 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share';
        }
        if (props.section.provider === 'vimeo') {
            return 'autoplay; fullscreen; picture-in-picture';
        }
        return '';
    });
</script>
