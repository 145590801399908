<template>
    <Switch
        :class="cn(
            'relative inline-flex h-5 w-10 shrink-0 rounded-full border shadow-inner',
            'focus-visible:ring-2 focus-visible:ring-offset-2', focusVisibleColor,
            'transition-colors duration-200 ease-in-out',
            'border-gray-200 bg-gray-50',
            disabled && 'cursor-not-allowed bg-white shadow-none',
            modelValue && !disabled && activeColor,
        )"
        :name="name"
        :model-value="modelValue"
        :disabled="disabled"
        @update:model-value="(value) => $emit('update:modelValue', value)"
    >
        <span
            :class="cn(
                'pointer-events-none m-px inline-block size-4 rounded-full ring-0 bg-white shadow',
                'transition duration-200 ease-in-out',
                'translate-x-0',
                modelValue && 'translate-x-5',
                disabled && 'bg-gray-200 shadow-sm',
            )"
            aria-hidden="true"
        />
    </Switch>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { Switch } from '@headlessui/vue';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = withDefaults(defineProps<{
        modelValue: boolean,
        disabled?: boolean,
        name?: string,
        color?: 'blue' | 'black',
    }>(), {
        disabled: false,
        color: 'blue',
    });

    defineEmits(['update:modelValue']);

    const focusVisibleColor = computed(() => {
        return {
            blue: 'focus-visible:ring-blue-500',
            black: 'focus-visible:ring-gray-500',
        }[props.color];
    });

    const activeColor = computed(() => {
        return {
            blue: 'border-blue-600 bg-blue-500',
            black: 'border-black bg-gray-950',
        }[props.color];
    });
</script>
