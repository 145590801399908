<template>
    <div class="flex flex-col gap-4">
        <div
            v-for="group in sortedOfferingGroups"
            :key="group.id || ''"
            class="space-y-4"
        >
            <h5
                v-if="group.name"
                class="text-lg md:-mb-2"
                v-text="group.name"
            />

            <ReservationOfferingListItem
                v-for="item in group.offerings"
                :key="item.id"
                v-model:entries="entries"
                :offering="item"
                :offering-type="item._type"
                :selected-slot="selectedSlot"
                :can-invite-members="canInviteMembers"
                :can-invite-guests="canInviteGuests"
                :guests-maximum="guestsMaximum"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { orderBy } from 'lodash-es';
    import { getTranslatedValue } from '@aspect/shared/plugins/i18n.ts';

    import ReservationOfferingListItem from '@aspect/ticket-office/components/reservation-offering-list-item.vue';

    import type { CreateEntryData, SlotData } from '@aspect/shared/types/generated';
    import type { Offering } from '@aspect/shared/types/global';

    // PROPS & EMIT
    const props = defineProps<{
        offerings: Offering[];
        selectedSlot: SlotData;
        canInviteMembers: boolean;
        canInviteGuests: boolean;
        guestsMaximum: number | null;
    }>();

    const entries = defineModel<CreateEntryData[]>('entries', { required: true });


    // SORTED OFFERINGS
    const sortedOfferings = computed<Offering[]>(() => {
        return orderBy(props.offerings, [
            (offering) => getTranslatedValue(offering.name).toLocaleLowerCase(), // Sort by name
            (offering) => offering.price || 0, // Sort by price in descending order
        ], ['asc', 'desc']);
    });

    interface OfferingGroup {
        id: string | null;
        name: string | null;
        offerings: Offering[];
    }


    // OFFERING GROUPS
    const offeringGroups = computed<OfferingGroup[]>(() => {
        const offeringGroups: OfferingGroup[] = [
            {
                id: null,
                name: null,
                offerings: []
            },
        ];

        for (const offering of sortedOfferings.value) {
            if (!offering.categories?.length) {
                offeringGroups[0].offerings.push(offering);
                continue;
            }

            offering.categories.forEach(category => {
                const offeringGroup = offeringGroups.find(ticketGroup => ticketGroup.id === category.id);

                if (!offeringGroup) {
                    offeringGroups.push({
                        id: category.id,
                        name: category.name,
                        offerings: [offering]
                    });
                } else {
                    offeringGroup.offerings.push(offering);
                }
            });
        }

        return offeringGroups;
    });


    // SORTED OFFERING GROUPS
    const sortedOfferingGroups = computed<OfferingGroup[]>(() => {
        const fileredGroups = offeringGroups.value.filter((ticketGroup) => ticketGroup.offerings.length);

        return orderBy(fileredGroups, [
            (ticketGroup) => ticketGroup.name?.toLocaleLowerCase(), // Sort by name
        ]);
    });
</script>
