import { computed, ref, watch } from 'vue';
import { useForm } from '@inertiajs/vue3';

import { date, format } from '@aspect/shared/utils/date.ts';
import { useRoute } from '@aspect/shared/composables/use-route.ts';
import { useAxiosForm } from '@aspect/shared/composables/use-axios-form.ts';

import type {
    SlotData,
    DivisionData,
    ConsumerUpdateSlotRequest,
} from '@aspect/shared/types/generated';
import type { Ref } from 'vue';

export function useReservationTimer({ division, selectedSlot, showSlots, activeSlot, limit = 240 }: {
    division: Ref<DivisionData>,
    selectedSlot: Ref<SlotData | null>,
    showSlots: Ref<boolean>,
    activeSlot: Ref<SlotData | null>,
    limit?: number,
}) {
    const { consumerRoute } = useRoute();

    const timerLimit = ref(limit);
    const timerRunning = ref(false);
    const lockLoading = ref(false);
    const reloadSlots = ref(false);

    const timerEnabled = computed(() => {
        return division.value.settings.ticketingTimer;
    });

    watch(selectedSlot, (value, oldValue) => {
        if (value) {
            startTimer(value);
        } else if (oldValue) {
            stopTimer(oldValue);
        }
    });

    async function updateSlot(slot: SlotData, payload: ConsumerUpdateSlotRequest) {
        const form = useForm<ConsumerUpdateSlotRequest>(payload);

        return await useAxiosForm(form).put(consumerRoute('/{division}/slots/{slot}', {
            division: division.value.id,
            slot: slot.id,
        }));
    }

    async function lockSlot(slot: SlotData) {
        lockLoading.value = true;

        const lockedAt = date();
        const lockedUntil = lockedAt.add(timerLimit.value, 'seconds');

        try {
            await updateSlot(slot, {
                lockedAt: format(lockedAt),
                lockedUntil: format(lockedUntil),
            });

            timerRunning.value = true;
        } catch (error) {
            timerRunning.value = false;
            showSlots.value = true;
            selectedSlot.value = null;
            reloadSlots.value = true;
        } finally {
            lockLoading.value = false;
        }
    }

    async function unlockSlot(slot: SlotData) {
        return await updateSlot(slot, {
            lockedAt: null,
            lockedUntil: null,
        });
    }

    async function startTimer(slot: SlotData) {
        if (!timerEnabled.value || timerRunning.value) {
            return;
        }

        await lockSlot(slot);
    }

    async function stopTimer(slot: SlotData | null) {
        if (!slot || !timerEnabled.value || !timerRunning.value) {
            return;
        }

        timerRunning.value = false;
        showSlots.value = true;
        activeSlot.value = slot;

        await unlockSlot(slot);
    }

    async function onTimerEnded() {
        stopTimer(selectedSlot.value);
    }

    return {
        timerLimit,
        timerRunning,
        lockLoading,
        reloadSlots,
        timerEnabled,
        onTimerEnded,
    };
}
