<template>
    <PopoverPortal>
        <PopoverContent
            :class="cn(
                'z-50 w-auto rounded ring-1 ring-gray-200 bg-white p-4 text-gray-950 shadow-md outline-none max-h-[--radix-popover-content-available-height]',
                'dark:ring-gray-800 dark:bg-gray-950 dark:text-gray-50',
                'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95',
                'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
                'data-[side=bottom]:slide-in-from-top-2',
                'data-[side=left]:slide-in-from-right-2',
                'data-[side=right]:slide-in-from-left-2',
                'data-[side=top]:slide-in-from-bottom-2',
                !autoWidth && 'w-[--radix-popover-trigger-width]',
                props.class,
            )"
            v-bind="{ ...forwarded, ...$attrs }"
        >
            <slot />
        </PopoverContent>
    </PopoverPortal>
</template>

<script lang="ts" setup>
    import { type HTMLAttributes, computed } from 'vue';
    import {
        PopoverContent,
        type PopoverContentEmits,
        type PopoverContentProps,
        PopoverPortal,
        useForwardPropsEmits,
    } from 'radix-vue';
    import cn from '@aspect/shared/utils/cn.ts';

    defineOptions({
        inheritAttrs: false,
    });

    const props = withDefaults(defineProps<PopoverContentProps & {
        class?: HTMLAttributes['class'],
        autoWidth?: boolean,
    }>(), {
        sideOffset: 8,
    });

    const emits = defineEmits<PopoverContentEmits>();

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, ...delegated } = props;

        return delegated;
    });

    const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>
