import { usePage } from '@inertiajs/vue3';

export function useSite() {
    const globals = computed<any>(() => {
        return usePage().props.globals;
    });

    const navs = computed<any>(() => {
        return usePage().props.navs;
    });

    const classes = {
        // Global
        rounding: {
            rounded: 'rounded',
            sharp: 'rounded-none',
            bubbly: 'rounded-xl',
        },

        // Sections
        sectionPadding: {
            normal: [
                'py-12',
                'lg:py-24'
            ],
            compact: [
                'py-6',
                'lg:py-12'
            ],
            spacious: [
                'py-16',
                'lg:py-32'
            ],
        },
        sectionGap: {
            normal: [
                'gap-6',
                'lg:gap-12'
            ],
            compact: [
                'gap-3',
                'lg:gap-6'
            ],
            spacious: [
                'gap-8',
                'lg:gap-16'
            ],
        },

        // Content
        contentPadding: {
            normal: [
                'p-3',
                'lg:p-6'
            ],
            compact: [
                'p-1.5',
                'lg:p-3'
            ],
            spacious: [
                'p-4',
                'lg:p-8'
            ],
        },
        contentPaddingHalf: {
            normal: [
                'p-1.5',
                'lg:p-3'
            ],
            compact: [
                'p-0.5',
                'lg:p-1.5'
            ],
            spacious: [
                'p-2',
                'lg:p-4'
            ],
        },
        contentPaddingQuarter: {
            normal: [
                'p-0.5',
                'lg:p-1.5'
            ],
            compact: [
                'p-px',
                'lg:p-0.5'
            ],
            spacious: [
                'p-1',
                'lg:p-2'
            ],
        },
        contentGap: {
            normal: [
                'gap-3',
                'lg:gap-6'
            ],
            compact: [
                'gap-1.5',
                'lg:gap-3'
            ],
            spacious: [
                'gap-4',
                'lg:gap-8'
            ],
        },
        contentGapHalf: {
            normal: [
                'gap-1.5',
                'lg:gap-3'
            ],
            compact: [
                'gap-0.5',
                'lg:gap-1.5'
            ],
            spacious: [
                'gap-2',
                'lg:gap-4'
            ],
        },
        contentGapDouble: {
            normal: [
                'gap-6',
                'lg:gap-12'
            ],
            compact: [
                'gap-3',
                'lg:gap-6'
            ],
            spacious: [
                'gap-8',
                'lg:gap-16'
            ],
        },
        contentMarginBottom: {
            normal: [
                'mb-3',
                'lg:mb-6'
            ],
            compact: [
                'mb-1.5',
                'lg:mb-3'
            ],
            spacious: [
                'mb-4',
                'lg:mb-8'
            ],
        },
        contentMarginBottomDouble: {
            normal: [
                'mb-6',
                'lg:mb-12'
            ],
            compact: [
                'mb-3',
                'lg:mb-6'
            ],
            spacious: [
                'mb-8',
                'lg:mb-16'
            ],
        },
        contentMarginBottomHalf: {
            normal: [
                'mb-1.5',
                'lg:mb-3'
            ],
            compact: [
                'mb-0.5',
                'lg:mb-1.5'
            ],
            spacious: [
                'mb-2',
                'lg:mb-4'
            ],
        },
        contentMarginBottomQuarter: {
            normal: [
                'mb-0.5',
                'lg:mb-1.5'
            ],
            compact: [
                'mb-0',
                'lg:mb-0.5'
            ],
            spacious: [
                'mb-1',
                'lg:mb-2'
            ],
        },

        // Text
        textAlignment: {
            'left': 'text-left',
            'center': 'text-center',
            'right': 'text-right',
            'justify-left': 'text-justify text-last-left',
            'justify-center': 'text-justify text-last-center',
            'justify-right': 'text-justify text-last-right',
        },

        // Backgrounds
        bgPosition: {
            'center': 'bg-center',
            'left': 'bg-left',
            'right': 'bg-right',
            'top': 'bg-top',
            'bottom': 'bg-bottom',
            'left top': 'bg-left-top',
            'left bottom': 'bg-left-bottom',
            'right top': 'bg-right-top',
            'right bottom': 'bg-right-bottom',
        },
        bgColor: {
            'black': 'bg-black',
            'dark-gray': 'bg-gray-900',
            'dark-gray-alt': 'bg-gray-800',
            'light-gray-alt': 'bg-gray-200',
            'light-gray': 'bg-gray-100',
            'white': 'bg-white',
            'primary': 'bg-tenant-500',
        },

        // Images
        // We need the *: classes for responsive images.
        imagePosition: {
            'center': 'object-center *:object-center',
            'left': 'object-left *:object-left',
            'right': 'object-right *:object-right',
            'top': 'object-top *:object-top',
            'bottom': 'object-bottom *:object-bottom',
            'left top': 'object-left-top *:object-left-top',
            'left bottom': 'object-left-bottom *:object-left-bottom',
            'right top': 'object-right-top *:object-right-top',
            'right bottom': 'object-right-bottom *:object-right-bottom',
        },
        imageAspectRatio: {
            picture: 'aspect-picture *:aspect-picture',
            portrait: 'aspect-portrait *:aspect-portrait',
            square: 'aspect-square *:aspect-square',
            full: 'aspect-full *:aspect-full',
            wide: 'aspect-wide *:aspect-wide',
            ultrawide: 'aspect-ultrawide *:aspect-ultrawide',
        },
        opticalAdjustement: {
            unset: 'p-4',
            wide: 'px-0',
            tall: 'py-0',
            square: 'p-8',
        },

        // Layout
        layoutGrid: {
            1: [
                'grid-cols-1',
            ],
            2: [
                'grid-cols-1',
                'sm:grid-cols-2',
            ],
            3: [
                'grid-cols-1',
                'md:grid-cols-2',
                'lg:grid-cols-3',
            ],
            4: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-4',
            ],
            5: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-3',
                'xl:grid-cols-5',
            ],
            6: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-3',
                'xl:grid-cols-6',
            ],
            7: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-3',
                'xl:grid-cols-7',
            ],
            8: [
                'grid-cols-1',
                'sm:grid-cols-2',
                'lg:grid-cols-4',
                'xl:grid-cols-8',
            ],
        },

        socialMediaIcon: {
            facebook: 'socials/fcebook',
            twitter: 'socials/x-twitter',
            instagram: 'socials/instagram',
            linkedin: 'socials/linkedin',
            youtube: 'socials/youtube',
            tiktok: 'socials/tiktok',
        }
    };

    return {
        globals,
        navs,
        classes: computed(() => {
            const spacing = globals.value?.styles.spacing.value || 'normal';

            return {
                ...classes,
                imageFramed: `bg-white shadow-md border border-gray-300 ${classes.contentPaddingHalf[spacing]}`,
            };
        }),
    };
}
