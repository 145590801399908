<template>
    <component
        :is="tag === 'a' ? 'a' : Link"
        :href="hrefFormatted"
        :rel="external ? 'external noopener noreferrer' : undefined"
        :target="external ? '_blank' : undefined"
        :download="download ? '' : undefined"
        :class="[
            'block font-normal underline decoration-gray-600/0 decoration-2 underline-offset-8 transition-all',
            'hover:decoration-gray-600 hover:underline-offset-4',
        ]"
    >
        <slot />
    </component>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { Link } from '@inertiajs/vue3';

    const props = withDefaults(defineProps<{
        tag?: 'a' | 'Link';
        type?: 'regular' | 'phone' | 'email';
        phone?: string;
        email?: string;
        url?: string;
        external?: boolean;
        download?: boolean;
        firstName?: string;
        lastName?: string;
    }>(), {
        tag: 'a',
        type: 'regular',
        external: false,
        download: false,
    });

    const hrefFormatted = computed(() => {
        if (props.type === 'phone') {
            return `tel:${props.phone}`;
        }

        if (props.type === 'email') {
            if (props.firstName && props.lastName) {
                return `mailto:${props.firstName} ${props.lastName}<${props.email}>`;
            }
            else if (props.firstName) {
                return `mailto:${props.firstName}<${props.email}>`;
            }
            else if (props.lastName) {
                return `mailto:${props.lastName}<${props.email}>`;
            }
            return `mailto:${props.email}`;
        }

        return props.url;
    });
</script>
