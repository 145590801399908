import type { Ref } from 'vue';
import { isEqual, orderBy } from 'lodash-es';

export interface SelectOption {
    label: string;
    value: any;
    subLabel?: string;
    disabled?: boolean;
}

export interface SelectProps {
    id?: string;
    name?: string;
    options: SelectOption[];
    autoWidth?: boolean;
    error?: string;
    tabindex?: string;
    multiple?: boolean;
    sorted?: boolean;
    align?: 'start' | 'center' | 'end';
    side?: 'top' | 'right' | 'bottom' | 'left';
    clearable?: boolean;
    disabled?: boolean;
    placeholder?: string;
    searchable?: boolean,
    autoSelect?: boolean,
    class?: string;
    triggerClass?: string;
    showCheckMark?: boolean;
    externalSearch?: boolean;
    externalSearchLoading?: boolean;
    loading?: boolean;
    contentClass?: string | string[];
    searchKey?: string;
}

export function useSelect(
    props: SelectProps,
    modelValue: Ref<any>,
    searchTerm: Ref<string>,
    open: Ref<boolean>,
) {
    // IS SELECTED
    function isSelected(value) {
        return !props.multiple
            ? isEqual(modelValue.value, value)
            : modelValue.value.some((v) => isEqual(v, value));
    }

    // ON SELECT
    function onSelect(option: SelectOption) {
        if (option.disabled) {
            return;
        }

        if (props.multiple) {
            if (isSelected(option.value)) {
                modelValue.value = modelValue.value.filter((v) => !isEqual(v, option.value));
            } else {
                modelValue.value = [...modelValue.value, option.value];
            }
        } else {
            modelValue.value = option.value;
            open.value = false;
        }
    }


    // SORTED OPTIONS
    const sortedOptions = computed<SelectOption[]>(() => {
        if (!props.sorted) {
            return props.options;
        }

        return orderBy(props.options, [option => option.label.toLowerCase()], ['asc']);
    });


    // SELECTED OPTIONS
    const selectedOptions = computed(() => {
        return sortedOptions.value.filter((option) => {
            return !props.multiple
                ? isEqual(modelValue.value, option.value)
                : modelValue.value.some((value) => isEqual(value, option.value));
        });
    });

    const selectedOptionsLabel = computed(() => selectedOptions.value.map((option) => option.label).join(', '));


    // AUTO SELECT
    onMounted(() => {
        const isEmpty = !modelValue.value || props.multiple && !modelValue.value.length;

        if (isEmpty && props.autoSelect && sortedOptions.value.length === 1) {
            modelValue.value = sortedOptions.value[0].value;
        }
    });


    // FILTER VALUES
    function filterValues(values, term) {
        if (!props.searchable || props.externalSearch) {
            return values;
        }

        return values.filter((value) => {
            return value.toLowerCase().includes(term.toLowerCase());
        });
    }


    // WAITING FOR SEARCH
    const waitingForSearch = computed(() => {
        if (!props.externalSearch) {
            return false;
        }

        if (props.options.length) {
            return false;
        }

        return !searchTerm.value || props.externalSearchLoading;
    });


    // SHOW EMPTY
    const showEmpty = computed(() => {
        return !props.options.length && !waitingForSearch.value;
    });


    // CLEAR BUTTON
    const showClearButton = computed(() => {
        if (!props.clearable) {
            return false;
        }

        return props.multiple ? modelValue.value.length > 0 : modelValue.value;
    });

    function onClear() {
        modelValue.value = props.multiple ? [] : null;
    }


    return {
        sortedOptions,
        selectedOptions,
        selectedOptionsLabel,
        waitingForSearch,
        showEmpty,
        showClearButton,

        onSelect,
        onClear,
        isSelected,
        filterValues,
    };
}
