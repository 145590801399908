import { computed, ref } from 'vue';
import { kebabCase } from 'lodash-es';

import { t } from '@aspect/shared/plugins/i18n.ts';

import { theme, remToPx } from '@aspect/shared/utils/tailwind.ts';
import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

function stylesToString(styles: Record<string, string>): string {
    return Object.entries(styles)
        .map(([key, value]) => `${kebabCase(key)}: ${value}`)
        .join('; ');
}

const monerisFrame = ref<HTMLIFrameElement|null>(null);
const errorCodes = ref<string[]>([]);

export function useMoneris() {
    const pageProps = usePageProps();

    const srcUrl = computed(() => {
        if (!pageProps.value.moneris?.hostedTokenUrl) {
            return '';
        }

        const url = new URL(pageProps.value.moneris.hostedTokenUrl);

        url.searchParams.append('id', pageProps.value.moneris.profileId);
        url.searchParams.append('pmmsg', 'true');
        url.searchParams.append('display_labels', '2');

        // Global Styles
        url.searchParams.append('css_body', stylesToString({
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
            gap: remToPx(theme.spacing['4']) as string,
            color: theme.colors.gray[800],
            fontSize: '16px',
            fontFamily: theme.fontFamily.sans,
        }));
        url.searchParams.append('css_textbox', stylesToString({
            fontSize: '14px',
            margin: '0',
            padding: '0',
            background: 'white',
            height: '38px',
            paddingRight: remToPx(theme.spacing['4']) as string,
            paddingLeft: remToPx(theme.spacing['4']) as string,
            paddingTop: remToPx(theme.spacing['2']) as string,
            paddingBottom: remToPx(theme.spacing['2']) as string,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.colors.gray[200],
            borderRadius: remToPx(theme.borderRadius.DEFAULT) as string,
        }));

        // Card Number
        url.searchParams.append('pan_label', t('Card Number'));
        url.searchParams.append('enable_cc_formatting', '1');
        url.searchParams.append('css_textbox_pan', stylesToString({
            gridColumn: 'span 2 / span 2',
        }));

        // Expiration
        url.searchParams.append('enable_exp', '1');
        url.searchParams.append('exp_label', t('MM/YY'));
        url.searchParams.append('enable_exp_formatting', '1');
        url.searchParams.append('css_textbox_exp', stylesToString({
            // gridColumn: 'span 1 / span ',
        }));

        // CVD
        url.searchParams.append('enable_cvd', '1');
        url.searchParams.append('cvd_label', 'CVV');
        url.searchParams.append('css_textbox_cvd', stylesToString({
            // gridColumn: 'span 1 / span ',
        }));

        return url.href;
    });

    function init(frame?: HTMLIFrameElement) {
        if (!frame) {
            throw new Error('Moneris iFrame not found.');
        }

        monerisFrame.value = frame;
    }

    function destroy() {
        monerisFrame.value = null;
    }

    function tokenize(): Promise<{ bin: string, token: string }> {
        return new Promise((resolve, reject) => {
            errorCodes.value = [];

            if (!pageProps.value.moneris?.hostedTokenUrl) {
                throw new Error('Moneris Hosted Token URL not found.');
            }

            if (!monerisFrame.value) {
                throw new Error('Moneris iFrame is not initialized.');
            }

            const onMessage = (event) => {
                const respData = JSON.parse(event.data);

                window.removeEventListener('message', onMessage, false);

                if (respData.errorMessage) {
                    errorCodes.value = respData.responseCode;
                    reject(respData.responseCode);
                    return;
                }

                resolve({
                    bin: respData.bin,
                    token: respData.dataKey,
                });
            };

            window.addEventListener('message', onMessage, false);

            monerisFrame.value.contentWindow?.postMessage('tokenize', pageProps.value.moneris.hostedTokenUrl);
        });
    }

    return {
        init,
        destroy,
        tokenize,
        srcUrl,
        monerisFrame,
        errorCodes,
    };
}
