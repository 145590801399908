import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { useRoute } from '@aspect/shared/composables/use-route.ts';
import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';
import { useAxiosForm } from '@aspect/shared/composables/use-axios-form.ts';

import type { MemberRequest, MemberData, SlotData } from '@aspect/shared/types/generated';

export function useMembers() {
    const pageProps = usePageProps();
    const { consumerRoute } = useRoute();

    const membersLoading = ref(false);

    async function getMembers(search: string, slot: SlotData): Promise<MemberData[]> {
        if (!search.trim()) {
            membersLoading.value = false;
            return [];
        }

        membersLoading.value = true;

        const form = useForm<MemberRequest>({
            slotId: slot.id,
            search,
        });

        const response = await useAxiosForm(form).get(consumerRoute('/{division}/members', {
            division: pageProps.value.division.id,
        }));

        if (!response) {
            return [];
        }

        membersLoading.value = false;

        return response.data.members;
    }

    return {
        membersLoading,
        getMembers,
    };
}
