<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            'flex flex-col justify-center',
            textColorClasses[section.notice_theme.value],
            bgColorClasses[section.notice_theme.value],
            classes.sectionPadding[globals.styles.spacing.value],
        ]"
    >
        <div
            :class="[
                'container grid grid-cols-1 lg:grid-cols-4',
                classes.contentGap[globals.styles.spacing.value],
            ]"
        >
            <div class="flex justify-center lg:justify-end">
                <AspectIcon :class="['size-24', textColorClasses[section.notice_theme.value]]" :name="iconName" />
            </div>

            <div
                :class="[
                    'text-last-center lg:text-last-left col-span-3 flex flex-col text-justify lg:text-justify',
                    classes.contentGapHalf[globals.styles.spacing.value],
                ]"
            >
                <h3 class="text-3xl text-white">
                    {{ section.notice_title }}
                </h3>
                <div class="lg:text-xl" v-html="section.notice_description" />
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    const textColorClasses = {
        info: 'text-tenant-50',
        warning: 'text-amber-50',
        danger: 'text-red-50',
    };

    const bgColorClasses = {
        info: 'bg-tenant-500',
        warning: 'bg-amber-500',
        danger: 'bg-red-500',
    };

    const iconName = computed(() => {
        return {
            info: 'information-circle',
            warning: 'warning-diamond',
            danger: 'warning-triangle',
        }[props.section.notice_theme.value];
    });
</script>
