<template>
    <div
        :class="[
            'grid flex-1 items-center gap-3 py-3 pr-3',
            'grid-cols-1 md:grid-cols-2 xl:grid-cols-4',
        ]"
    >
        <div v-if="slot.capacity === null" class="col-span-full text-sm italic">
            {{ t('Unlimited spots available') }}
        </div>
        <template v-else>
            <!-- Do not extract this in a component, it's there for performance reasons. -->
            <article
                v-for="entry in entries.slice(0, 16)"
                :key="entry.code"
                class="isolate flex flex-1"
            >
                <figure
                    :class="cn(
                        'relative z-10 -mr-6 shrink-0 size-12 rounded-full p-1.5',
                        !entry.id && 'border border-dashed border-gray-300 bg-gray-100 shadow-inner-md',
                        entry.id && 'bg-white shadow-md',
                        // !entry.id && selectedSlot === slot.time && 'border-gray-950',
                    )"
                >
                    <!-- <div
                        :class="[
                            'absolute inset-0 m-1.5 flex items-center justify-center rounded-full bg-gray-950 opacity-0 shadow-md shadow-gray-950/50 transition',
                            !entry.id && selectedSlot === slot.time && 'opacity-100',
                        ]"
                    >
                        <AspectIcon name="check" class="size-6 text-white" />
                    </div> -->
                    <img
                        v-if="entry.picture"
                        class="size-full rounded-full object-cover"
                        alt="Profile Picture"
                        :src="entry.picture"
                    >
                    <div v-else class="size-full rounded-full border border-dashed border-gray-300 text-gray-500 shadow-md">
                        <AspectIcon
                            v-if="isRestricted || slot.state === 'locked'"
                            class="p-1.5"
                            name="padlock-square-1"
                        />
                        <AspectIcon
                            v-else-if="slot.state === 'hidden'"
                            class="p-1.5"
                            name="invisible-1"
                        />
                        <AspectIcon
                            v-else
                            class="p-1.5"
                            name="help-question-1"
                        />
                    </div>
                </figure>

                <div
                    :class="cn(
                        'flex grow justify-between items-center shrink rounded-r py-1.5 pl-8 gap-1.5 pr-1.5',
                        entry.id && 'bg-white shadow-md',
                        !entry.id && 'border border-dashed border-gray-300 bg-gray-100 shadow-inner-md',
                        // !entry.id && selectedSlot === slot.time && 'border-gray-950',
                    )"
                >
                    <h4 class="truncate text-sm">
                        <template v-if="entry.id">
                            {{ entry.customer?.name || t('Reserved') }}
                        </template>
                        <template v-else-if="slot.restrictions?.pastRestricted">
                            {{ t('Restricted') }}
                        </template>
                        <template v-else-if="slot.restrictions?.lockedRestricted || slot.restrictions?.stateRestricted">
                            {{ t('Locked') }}
                        </template>
                        <template v-else-if="!slot.isAvailable">
                            {{ t('Coming') }}
                        </template>
                        <template v-else-if="isRestricted">
                            {{ t('Restricted') }}
                        </template>
                        <template v-else-if="slot.state === 'hidden'">
                            {{ t('Hidden') }}
                        </template>
                        <template v-else-if="slot.state === 'locked'">
                            {{ t('Locked') }}
                        </template>
                        <template v-else>
                            {{ t('Available') }}
                        </template>
                    </h4>

                    <div v-if="entry.customer?.memberNumber" class="flex shrink-0 items-center gap-1.5">
                        <AspectIcon name="sign-hashtag" class="size-3" />
                        <AspectData type="id">
                            {{ entry.customer.memberNumber }}
                        </AspectData>
                    </div>
                </div>
            </article>

            <div v-if="entries.length > 16" class="col-span-full text-center italic">
                {{ t('And :amount more...', { amount: (entries.length - 16).toString() }) }}
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import cn from '@aspect/shared/utils/cn.ts';
    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { useSlotEntries } from '@aspect/ticket-office/composables/use-slot-entries.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectData from '@aspect/shared/components/aspect-data.vue';

    import type { SlotData } from '@aspect/shared/types/generated';

    const props = withDefaults(defineProps<{
        slot: SlotData;
        isRestricted?: boolean;
    }>(), {
        isRestricted: false,
    });

    const slot = computed(() => props.slot);

    const { entries } = useSlotEntries(slot);
</script>
