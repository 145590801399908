<template>
    <div class="group grid grid-cols-1">
        <div class="flex items-stretch justify-between bg-white group-first:rounded-t">
            <button
                :class="[
                    'shrink-0 border-r border-gray-200 p-1.5 text-gray-700',
                    'group-first:rounded-tl',
                    'hover:bg-gray-100',
                    'md:p-3'
                ]"
                @click="previousMonth"
            >
                <AspectIcon class="size-3 md:size-5" name="arrow-left-3" />
            </button>
            <AspectDatePicker
                :close-on-auto-apply="true"
                :model-value="currentMonth"
                mode="month"
                @update:model-value="updateMonth"
            >
                <button class="size-full p-1.5 text-center text-sm font-semibold uppercase tracking-wide hover:bg-gray-100">
                    <template v-if="startOfWeek.isSame(endOfWeek, 'month')">
                        {{ format(startOfWeek, 'MMMM YYYY') }}
                    </template>
                    <template v-else>
                        {{ format(startOfWeek, 'MMM YYYY') }} - {{ format(endOfWeek, 'MMM YYYY') }}
                    </template>
                </button>
            </AspectDatePicker>
            <button
                :class="[
                    'shrink-0 border-l border-gray-200 p-1.5 text-gray-700',
                    'group-first:rounded-tr',
                    'hover:bg-gray-100',
                    'md:p-3'
                ]"
                @click="nextMonth"
            >
                <AspectIcon class="size-3 md:size-5" name="arrow-right-3" />
            </button>
        </div>

        <div class="flex justify-between border-t border-gray-200 bg-white group-last:rounded-b">
            <button
                :class="[
                    'shrink-0 border-r border-gray-200 p-1.5 text-gray-700',
                    'group-last:rounded-bl',
                    'hover:bg-gray-100',
                    'md:p-3'
                ]"
                @click="previousWeek"
            >
                <AspectIcon class="size-3 md:size-5" name="arrow-left-3" />
            </button>

            <div
                :class="[
                    'relative grid grow grid-cols-7 p-1.5',
                    'md:p-3'
                ]"
            >
                <ReservationDayPickerItem
                    v-for="day in days"
                    :key="day.date.format()"
                    v-model:selected-day="selectedDay"
                    :day="day.date"
                    :availability="day.availability"
                    :slots="day.slots"
                    :loading="loading"
                    @click="emit('click')"
                />
            </div>

            <button
                :class="[
                    'shrink-0 border-l border-gray-200 p-1.5 text-gray-700',
                    'group-last:rounded-br',
                    'hover:bg-gray-100',
                    'md:p-3'
                ]"
                @click="nextWeek"
            >
                <AspectIcon class="size-3 md:size-5" name="arrow-right-3" />
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';

    import { date, format } from '@aspect/shared/utils/date.ts';
    import { useSlots } from '@aspect/ticket-office/composables/use-slots.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectDatePicker from '@aspect/shared/components/aspect-date-picker.vue';

    import ReservationDayPickerItem from '@aspect/ticket-office/components/reservation-day-picker-item.vue';

    import type { Dayjs } from 'dayjs';
    import type { Availability } from '@aspect/shared/types/global';
    import type { SlotData } from '@aspect/shared/types/generated';


    interface ReservationDay {
        date: Dayjs;
        availability: Availability;
        slots: SlotData[];
    }

    // PROPS & EMIT
    const props = defineProps<{
        slotsByDay: Record<string, SlotData[]>;
        loading: boolean;
    }>();
    const emit = defineEmits<{
        click: [],
    }>();


    // SELECTED DAY
    const selectedDay = defineModel<Dayjs | null>('selectedDay', { required: true });


    // ACTIVE DAY
    const activeDay = defineModel<Dayjs>('activeDay', { required: true });

    function previousMonth() {
        activeDay.value = activeDay.value.subtract(1, 'month').set('date', 1);
    }

    function nextMonth() {
        activeDay.value = activeDay.value.add(1, 'month').set('date', 1);
    }

    function previousWeek() {
        activeDay.value = activeDay.value.subtract(1, 'week');
    }

    function nextWeek() {
        activeDay.value = activeDay.value.add(1, 'week');
    }

    function updateMonth(value) {
        const updatedMonth = date(value, 'YYYY-MM');

        activeDay.value = activeDay.value
            .set('month', updatedMonth.month())
            .set('year', updatedMonth.year());
    }


    // START OF WEEK
    const startOfWeek = computed(() => {
        return activeDay.value.startOf('week');
    });


    // END OF WEEK
    const endOfWeek = computed(() => {
        return activeDay.value.endOf('week');
    });


    // CURRENT MONTH
    const currentMonth = computed(() => {
        return activeDay.value.format('YYYY-MM');
    });


    // DAYS
    const days = computed<ReservationDay[]>(() => {
        return Array.from({ length: 7 }, (_, i) => {
            const day = startOfWeek.value.add(i, 'day');
            const slots = props.slotsByDay[day.format('YYYY-MM-DD')] || [];

            const { availability } = useSlots(slots);

            return {
                date: day,
                availability: availability.value,
                slots,
            };
        });
    });
</script>
