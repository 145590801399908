<template>
    <DialogPortal>
        <DialogOverlay
            :class="[
                'fixed inset-0 z-50 bg-gray-950/75',
                // 'bg-white/75 backdrop-blur-sm',
                'data-[state=open]:animate-in data-[state=open]:fade-in-0',
                'data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
                // 'dark:bg-gray-950/75',
            ]"
        />
        <DialogContent
            :class="cn(
                'fixed w-full left-1/2 top-1/2 z-50 translate-x-[-50%] translate-y-[-50%] duration-200',
                'sm:p-6',
                'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
                'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]',
                // 'data-[state=open]:zoom-in-95',
                // 'data-[state=closed]:zoom-out-95',
            )"
            v-bind="{ ...props, ...emitsAsProps }"
        >
            <div
                :class="
                    cn(
                        'bg-white shadow-lg overflow-y-auto max-h-screen',
                        'sm:rounded sm:max-h-[calc(100vh-3rem)]',
                        'dark:bg-gray-950',
                        props.class,
                    )
                "
            >
                <slot />
            </div>
        </DialogContent>
    </DialogPortal>
</template>

<script lang="ts" setup>
    import {
        DialogContent,
        type DialogContentEmits,
        type DialogContentProps,
        DialogOverlay,
        DialogPortal,
        useEmitAsProps,
    } from 'radix-vue';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = defineProps<DialogContentProps & { class?: string }>();
    const emits = defineEmits<DialogContentEmits>();

    const emitsAsProps = useEmitAsProps(emits);
</script>
