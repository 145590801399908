<template>
    <div
        :class="
            cn(
                'z-10 sticky bottom-0 border-t border-gray-200 bg-white',
                props.class,
            )
        "
    >
        <slot />
    </div>
</template>

<script lang="ts" setup>
    import cn from '@aspect/shared/utils/cn.ts';

    interface DialogFooterProps {
        class?: string
    }

    const props = defineProps<DialogFooterProps>();
</script>
