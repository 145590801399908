import axios from 'axios';

let assetUrlResolver = () => {
    try {
        return import.meta.env.VITE_VAPOR_ASSET_URL
            ? import.meta.env.VITE_VAPOR_ASSET_URL
            : '';
    } catch (e) {
        console.error('Unable to automatically resolve the asset URL. Use Vapor.withBaseAssetUrl() to specify it manually.');

        throw e;
    }
};

// Inspired from laravel-vapor package: https://github.com/laravel/vapor-js/blob/master/src/index.js
export function useLaravelVapor() {
    return {
        /**
         * Generate the S3 URL to an application asset.
         */
        asset(path: string) {
            return assetUrlResolver() + '/' + path.replace(/^\/+/, '');
        },

        /**
         * Set the base URL for assets.
         */
        withBaseAssetUrl(url: string) {
            assetUrlResolver = () => url ? url : '';
        },

        /**
         * Store a file in S3 and return its UUID, key, and other information.
         */
        async store(file, options: any = {}) {
            const response = await axios.post(options.signedStorageUrl ? options.signedStorageUrl : '/vapor/signed-storage-url', {
                bucket: options.bucket || '',
                content_type: options.contentType || file.type,
                expires: options.expires || '',
                visibility: options.visibility || '',
                ...options.data
            }, {
                baseURL: options.baseURL || null,
                headers: options.headers || {},
                ...options.options
            });

            const headers = response.data.headers;

            if ('Host' in headers) {
                delete headers.Host;
            }

            if (typeof options.progress === 'undefined') {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                options.progress = () => {};
            }

            const cancelToken = options.cancelToken || '';

            // Clear axios defaults before sending to AWS.
            const awsAxios = axios.create();

            Object.keys(awsAxios.defaults.headers).forEach((key) => {
                awsAxios.defaults.headers[key] = null;
            });

            await awsAxios.put(response.data.url, file, {
                cancelToken,
                headers: {
                    Accept: 'application/json',
                    ...headers,
                },
                onUploadProgress: (progressEvent: any) => {
                    options.progress(progressEvent.loaded / progressEvent.total);
                }
            });

            response.data.extension = file.name.split('.').pop();

            return response.data;
        },
    };
}
