<template>
    <component
        :is="linkTypeHref ? 'a' : 'aside'"
        v-if="visible"
        :class="[
            'flex w-screen py-3',
            textColorClasses[banner.theme.value],
            bgColorClasses[banner.theme.value],
        ]"
        :href="linkTypeHref"
    >
        <div
            :class="[
                'container flex items-center justify-between',
                classes.contentGapHalf[globals.styles.spacing.value]
            ]"
        >
            <div :class="['flex items-center', classes.contentGapHalf[globals.styles.spacing.value]]">
                <AspectIcon :class="['size-6 shrink-0', textColorClasses[banner.theme.value]]" :name="iconName" />

                <div class="text-base">
                    <span class="text-xl text-white">
                        {{ banner.title }} –
                    </span>
                    {{ banner.message }}
                </div>
            </div>


            <button @click="visible = false">
                <AspectIcon
                    v-if="banner.clearable"
                    :class="['size-6', textColorClasses[banner.theme.value]]"
                    name="delete-circle"
                />
            </button>
        </div>
    </component>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const { classes, globals } = useSite();

    const visible = ref(true);

    const banner = computed(() => {
        return globals.value.site_wide_banner;
    });

    const textColorClasses = {
        info: 'text-tenant-50',
        warning: 'text-amber-50',
        danger: 'text-red-50',
    };

    const bgColorClasses = {
        info: 'bg-tenant-500',
        warning: 'bg-amber-500',
        danger: 'bg-red-500',
    };

    const iconName = computed(() => {
        return {
            info: 'information-circle',
            warning: 'warning-diamond',
            danger: 'warning-triangle',
        }[banner.value.theme.value];
    });

    const linkTypeHref = computed(() => {
        // Files
        if (banner.value.link_type.value === 'file') {
            return banner.value.file.url;
        }

        // Email
        if (banner.value.link_type.value === 'email' && banner.value.email_address) {
            return banner.value.full_name ? `mailto:${banner.value.full_name}<${banner.value.email_address}>` : `mailto:${banner.value.email_address}`;
        }

        // Phone
        if (banner.value.link_type.value === 'phone' && banner.value.phone_number) {
            return `tel:${banner.value.phone_number}`;
        }

        // Webpages
        if (banner.value.link_type.value === 'webpage') {
            return `${banner.value.link || ''}${banner.value.fragment_identifier ? `#${banner.value.fragment_identifier}` : ''}`;
        }

        return '';
    });
</script>
