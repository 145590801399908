import Big from 'big.js';
import { isNaN } from 'lodash-es';
import { currentLocaleTag } from '../plugins/i18n.ts';

export const formatCurrency = (amount: number | null | undefined, currency: 'CAD' | 'USD' = 'CAD', minimumFractionDigits = 2): string => {
    if (amount === null || amount === undefined) {
        return '';
    }

    let numberFormat: Intl.NumberFormat;

    try {
        numberFormat = new Intl.NumberFormat(currentLocaleTag.value, {
            style: 'currency',
            currency,
            minimumFractionDigits,
            currencyDisplay: 'narrowSymbol',
        });
    } catch (error) {
        // "narrowSymbol" not supported on older Safari...
        // https://bugs.webkit.org/show_bug.cgi?id=226297
        numberFormat = new Intl.NumberFormat(currentLocaleTag.value, {
            style: 'currency',
            currency,
            minimumFractionDigits,
            currencyDisplay: 'symbol',
        });
    }

    return numberFormat.format(new Big(amount).div(100).toNumber());
};

export const formatNumber = (number: number | null, minimumFractionDigits = 0): string => {
    if (number === null) {
        return '';
    }

    const numberFormat = new Intl.NumberFormat(currentLocaleTag.value, {
        style: 'decimal',
        minimumFractionDigits,
    });

    return numberFormat.format(number);
};

export const formatPercent = (percent: number | null, maximumFractionDigits = 2): string => {
    if (percent === null) {
        return '';
    }

    if (isNaN(percent)) {
        percent = 0;
    }

    if (percent === Infinity) {
        return 'N/A';
    }

    const numberFormat = new Intl.NumberFormat(currentLocaleTag.value, {
        style: 'percent',
        maximumFractionDigits,
    });

    // 10% would be 10, but we want 0.1, so we divide by 100.
    return numberFormat.format(percent / 100);
};
