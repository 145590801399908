<template>
    <DialogDescription
        :class="cn('text-gray-600 text-sm dark:text-gray-400', props.class)"
        v-bind="props"
    >
        <slot />
    </DialogDescription>
</template>

<script lang="ts" setup>
    import { DialogDescription, type DialogDescriptionProps } from 'radix-vue';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = defineProps<DialogDescriptionProps & { class?: string }>();
</script>
