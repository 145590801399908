<template>
    <div class="flex flex-col items-center justify-stretch">
        <div
            :class="[
                'mb-1.5 text-xs font-semibold uppercase tracking-wide',
                'md:text-sm',
                isDisabled ? 'text-gray-500' : 'text-gray-700',
            ]"
        >
            <span class="hidden 2xl:inline">
                {{ format(day, 'dddd') }}
            </span>
            <span class="hidden sm:inline 2xl:hidden">
                {{ format(day, 'ddd') }}
            </span>
            <span class="sm:hidden">
                {{ format(day, 'dd')[0] }}
            </span>
        </div>

        <ReservationDayPickerItemButton
            :is-disabled="isDisabled"
            :is-selected="isSelected"
            :is-loading="loading"
            :availability="availability"
            :day="day"
            @click="onSelect"
        />
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { format } from '@aspect/shared/utils/date.ts';

    import ReservationDayPickerItemButton from '@aspect/ticket-office/components/reservation-day-picker-item-button.vue';

    import type { Dayjs } from 'dayjs';
    import type { SlotData } from '@aspect/shared/types/generated';
    import type { Availability } from '@aspect/shared/types/global';


    const props = defineProps<{
        day: Dayjs;
        availability: Availability;
        slots: SlotData[];
        loading: boolean;
    }>();
    const emit = defineEmits<{
        click: [],
    }>();

    const selectedDay = defineModel<Dayjs | null>('selectedDay', { required: true });

    const isDisabled = computed(() => {
        return props.slots.every(slot => !slot.isAvailable);
    });

    const isSelected = computed(() => {
        return props.day.isSame(selectedDay.value, 'day');
    });

    function onSelect() {
        selectedDay.value = props.day;
        emit('click');
    }
</script>
