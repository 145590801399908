<template>
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <AspectInputGroup class="">
            <AspectLabel :required="true" for="firstName">
                {{ t('First Name') }}
            </AspectLabel>
            <AspectInput
                v-model="(form.customer as CreateReservationCustomerData).firstName"
                :error="form.errors['customer.firstName']"
                name="firstName"
            />
        </AspectInputGroup>

        <AspectInputGroup class="">
            <AspectLabel :required="true" for="lastName">
                {{ t('Last Name') }}
            </AspectLabel>
            <AspectInput
                v-model="(form.customer as CreateReservationCustomerData).lastName"
                :error="form.errors['customer.lastName']"
                name="lastName"
            />
        </AspectInputGroup>

        <AspectInputGroup class="lg:col-span-2">
            <AspectLabel for="organizationName">
                {{ t('Organization Name') }}
            </AspectLabel>
            <AspectInput
                v-model="(form.customer as CreateReservationCustomerData).organizationName"
                :error="form.errors['customer.organizationName']"
                name="organizationName"
            />
        </AspectInputGroup>

        <AspectInputGroup class="">
            <AspectLabel :required="true" for="email">
                {{ t('Email') }}
            </AspectLabel>
            <AspectInput
                v-model="(form.customer as CreateReservationCustomerData).email"
                :error="form.errors['customer.email']"
                name="email"
                type="email"
            />
        </AspectInputGroup>

        <AspectInputGroup class="">
            <AspectLabel for="phone">
                {{ t('Phone') }}
            </AspectLabel>
            <AspectInput
                v-model="(form.customer as CreateReservationCustomerData).phone"
                :error="form.errors['customer.phone']"
                mask="phoneWithCountry"
                name="phone"
                type="tel"
            />
        </AspectInputGroup>

        <!-- TODO: Support other countries and provinces -->
        <!--<AspectInputGroup>
            <AspectLabel for="country">
                {{ t('Pays') }}
            </AspectLabel>
            <ConsumerSelect
                v-model="(form.customer as CreateReservationCustomerData).country"
                :error="form.errors['customer.country']"
                :options="countryOptions"
                name="country"
            />
        </AspectInputGroup>-->
        <!--<AspectInputGroup>
            <AspectLabel for="province">
                {{ t('Province') }}
            </AspectLabel>
            <AspectInput
                v-model="(form.customer as CreateReservationCustomerData).province"
                :error="form.errors['customer.province']"
                name="province"
            />
        </AspectInputGroup>-->

        <AspectInputGroup class="lg:col-span-2">
            <AspectLabel for="streetAddress">
                {{ t('Street Address') }}
            </AspectLabel>
            <AspectInput
                v-model="(form.customer as CreateReservationCustomerData).streetAddress"
                :error="form.errors['customer.streetAddress']"
                name="streetAddress"
            />
        </AspectInputGroup>

        <AspectInputGroup class="">
            <AspectLabel for="city">
                {{ t('City') }}
            </AspectLabel>
            <AspectInput
                v-model="(form.customer as CreateReservationCustomerData).city"
                :error="form.errors['customer.city']"
                name="city"
            />
        </AspectInputGroup>

        <AspectInputGroup class="">
            <AspectLabel for="postalCode">
                {{ t('Postal Code') }}
            </AspectLabel>
            <AspectInput
                v-model="(form.customer as CreateReservationCustomerData).postalCode"
                :error="form.errors['customer.postalCode']"
                mask="postalCode"
                name="postalCode"
            />
        </AspectInputGroup>
    </div>
</template>

<script lang="ts" setup>
    import { t } from '@aspect/shared/plugins/i18n.ts';

    import AspectInputGroup from '@aspect/shared/components/aspect-input-group.vue';
    import AspectInput from '@aspect/shared/components/aspect-input.vue';
    import AspectLabel from '@aspect/shared/components/aspect-label.vue';

    import type { InertiaForm } from '@inertiajs/vue3';
    import type { CreateReservationCustomerData, CreateReservationData } from '@aspect/shared/types/generated';

    const form = defineModel<InertiaForm<CreateReservationData>>({ required: true });
</script>
