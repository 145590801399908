<template>
    <iframe
        v-if="srcUrl"
        ref="monerisFrame"
        :src="srcUrl"
        class="h-[38px] grayscale"
    />
    <AspectAlert v-if="errors.length" type="error">
        <ul>
            <li v-for="error in errors" :key="error">
                {{ error }}
            </li>
        </ul>
    </AspectAlert>
    <AspectAlert v-if="errorCodes.length && !errors.length" type="error">
        {{ t('We are currently experiencing issues with our payment provider.') }}
        {{ t('We apologize for any inconvenience this may cause and kindly request that you try again at a later time.') }}<br>
        {{ tc('Code :code|Codes :code', errorCodes.length, { code: errorCodes.join(', ') }) }}
    </AspectAlert>
</template>

<script lang="ts" setup>
    import { ref, computed, onMounted, onUnmounted } from 'vue';
    import { t, tc } from '@aspect/shared/plugins/i18n.ts';
    import { useMoneris } from '@aspect/ticket-office/composables/use-moneris.ts';

    import AspectAlert from '@aspect/shared/components/aspect-alert.vue';

    const monerisFrame = ref<HTMLIFrameElement>();

    const {
        init,
        destroy,
        srcUrl,
        errorCodes,
    } = useMoneris();

    onMounted(() => {
        init(monerisFrame.value);
    });

    onUnmounted(() => {
        destroy();
    });

    const errors = computed(() => {
        return [...errorCodes.value]
            .sort()
            .map(errorCode => ({
                943: t('Invalid card number.'),
                944: t('Invalid expiration date.'),
                945: t('Invalid CVV.'),
            }[errorCode]))
            .filter(error => !!error);
    });
</script>
