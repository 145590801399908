<template>
    <div class="relative flex">
        <!-- Fade -->
        <div class="pointer-events-none absolute inset-0 z-30 size-full">
            <figure
                :class="[
                    'absolute inset-y-0 left-0 z-10 max-h-full w-12 shrink-0 bg-gradient-to-r from-white/90 to-white/0 transition',
                    showLeftFade ? 'opacity-100' : 'opacity-0',
                ]"
            />
            <figure
                :class="[
                    'absolute inset-y-0 right-0 z-10 max-h-full shrink-0 bg-gradient-to-l from-white/90 to-white/0 transition',
                    'w-32 md:w-64',
                    showRightFade ? 'opacity-100' : 'opacity-0',
                ]"
            />
        </div>

        <!-- Animated Scroll Indicator -->
        <div class="pointer-events-none absolute inset-x-0 top-64 z-10 h-dvh w-full pr-4">
            <figure
                :class="[
                    'pointer-events-none sticky top-1/2 z-10 flex justify-end transition',
                    showRightFade ? 'opacity-100' : 'opacity-0',
                ]"
            >
                <AspectIcon
                    :class="[
                        'size-8 text-gray-500',
                        'animate-out slide-out-to-left-8 zoom-out-90 fade-out repeat-infinite animate-duration-2000 ease-in-out',
                    ]"
                    name="circle"
                />
            </figure>
        </div>

        <div class="relative flex-1">
            <header class="sticky top-16 z-20 border-b border-gray-200 bg-white/80 backdrop-blur">
                <div
                    class="syncscroll flex overflow-auto overflow-x-hidden [&>*:nth-child(2)]:border-l-0"
                    name="slot-picker"
                >
                    <div class="h-10 w-24 shrink-0 border-r border-gray-200" />
                    <div
                        v-for="schedule in slotsBySchedules"
                        :key="schedule.id"
                        class="flex h-10 min-w-64 flex-1 items-center border-l border-gray-200 px-4 font-medium sm:min-w-96"
                    >
                        {{ schedule.locationName }}
                    </div>
                </div>
            </header>

            <div
                ref="scrollContainer"
                class="syncscroll flex overflow-auto"
                name="slot-picker"
                @scroll="onScroll"
            >
                <div class="sticky left-0 z-30 flex w-24 shrink-0 flex-col border-r border-gray-200 bg-white/80 backdrop-blur">
                    <div
                        class="grid flex-1 grid-cols-1"
                        :style="`grid-template-rows: repeat(${durationSteps.length}, minmax(0, 1fr));`"
                    >
                        <div
                            v-for="step in durationSteps"
                            :key="step.format()"
                            class="flex items-start justify-start border-b border-gray-200"
                        >
                            <AspectData type="time" class="p-2 text-sm">
                                {{ formatTime(step) }}
                            </AspectData>
                        </div>
                    </div>
                </div>

                <div class="flex flex-1 divide-x divide-gray-200">
                    <ReservationSlotPickerAgendaColumn
                        v-for="schedule in slotsBySchedules"
                        :key="schedule.id"
                        class="min-w-64 sm:min-w-96"
                        :duration-steps="durationSteps"
                        :schedule="schedule"
                        :active-slot="activeSlot"
                        @select="value => emit('select', value)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, onMounted, ref } from 'vue';
    import { orderBy } from 'lodash-es';

    import { date, formatTime } from '@aspect/shared/utils/date.ts';
    import { getDurationIncrement } from '@aspect/ticket-office/lib/slots.ts';
    import { syncscroll } from '@aspect/ticket-office/lib/syncscroll.js';

    import AspectData from '@aspect/shared/components/aspect-data.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    import ReservationSlotPickerAgendaColumn from '@aspect/ticket-office/components/reservation-slot-picker-agenda-column.vue';

    import type { SlotData } from '@aspect/shared/types/generated';
    import type { Dayjs } from 'dayjs';

    const props = defineProps<{
        slots: SlotData[];
        activeSlot: SlotData | null;
        selectedDay: Dayjs;
    }>();
    const emit = defineEmits<{
        select: [value: SlotData];
    }>();

    export interface FormattedSlot extends SlotData {
        startAt: Dayjs;
        endAt: Dayjs;
    }

    export interface SlotsBySchedule {
        id: string;
        locationName: string | null;
        slots: FormattedSlot[];
    }


    // SCROLL FADES
    const scrollContainer = ref<HTMLElement>();
    const scrollPosition = ref(0);

    const showLeftFade = computed(() => {
        return scrollPosition.value > 0;
    });

    const showRightFade = computed(() => {
        if (!scrollContainer.value) {
            return false;
        }

        const { scrollWidth, clientWidth } = scrollContainer.value;

        if (scrollWidth > clientWidth) {
            return scrollPosition.value + clientWidth < scrollWidth;
        }

        return false;
    });

    const onScroll = () => {
        scrollPosition.value = scrollContainer.value ? scrollContainer.value.scrollLeft : 0;
    };


    // FORMATTED SLOTS
    const formattedSlots = computed<FormattedSlot[]>(() => {
        return props.slots.map(slot => {
            const startAt = date(slot.dateTime);
            const endAt = startAt.add(slot.duration, 'minute');

            return {
                ...slot,
                startAt,
                endAt,
            };
        });
    });


    // SLOTS BY SCHEDULES
    const slotsBySchedules = computed<SlotsBySchedule[]>(() => {
        return orderBy(
            formattedSlots.value.reduce((slotsBySchedules: SlotsBySchedule[], slot: FormattedSlot) => {
                let schedule = slotsBySchedules.find(schedule => schedule.id === slot.scheduleId);

                if (!schedule) {
                    schedule = {
                        id: slot.scheduleId,
                        locationName: slot.scheduleLocation?.name || null,
                        slots: [],
                    };

                    slotsBySchedules.push(schedule);
                }

                schedule.slots.push(slot);

                return slotsBySchedules;
            }, []),
            ['locationName', 'id'],
            ['asc', 'asc'],
        );
    });


    // DURATION INCREMENT
    const durationIncrement = computed<number>(() => {
        return getDurationIncrement(props.slots);
    });

    // START AT
    const startAt = computed<Dayjs>(() => {
        return formattedSlots.value.reduce((previous: Dayjs, current) => {
            return current.startAt.isBefore(previous) ? current.startAt : previous;
        }, date(props.selectedDay).endOf('day'));
    });


    // END AT
    const endAt = computed<Dayjs>(() => {
        return formattedSlots.value.reduce((previous: Dayjs, current) => {
            return current.endAt.isAfter(previous) ? current.endAt : previous;
        }, date(props.selectedDay).startOf('day'));
    });


    // DURATION STEPS
    const durationSteps = computed<Dayjs[]>(() => {
        const steps: Dayjs[] = [];
        let current = startAt.value;

        while (current.isBefore(endAt.value) || current.isSame(endAt.value)) {
            steps.push(current);
            current = current.add(durationIncrement.value, 'minute');
        }

        return steps;
    });

    onMounted(() => {
        syncscroll();
    });
</script>
