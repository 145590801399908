<template>
    <component
        :is="searchTerm ? ComboboxEmpty : 'div'"
        :class="cn('p-6 text-center text-sm text-gray-600 italic', $attrs.class ?? '')"
        v-bind="props"
    >
        <slot />
    </component>
</template>

<script lang="ts" setup>
    import type { ComboboxEmptyProps } from 'radix-vue';
    import { ComboboxEmpty } from 'radix-vue';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = defineProps<ComboboxEmptyProps & {
        searchTerm?: string;
    }>();
</script>
