<template>
    <div class="flex flex-1 flex-col">
        <div class="grid flex-1 grid-cols-1">
            <div
                class="col-start-1 row-start-1 grid grid-cols-1"
                :style="`grid-template-rows: repeat(${durationSteps.length}, minmax(0, 1fr));`"
            >
                <div
                    v-for="step in durationSteps"
                    :key="step.format()"
                    class="border-b border-gray-200"
                />
            </div>
            <div
                class="col-start-1 row-start-1 grid grid-cols-1"
                :style="`grid-template-rows: repeat(${durationSteps.length}, minmax(0, 1fr));`"
            >
                <ReservationSlotPickerAgendaColumnItem
                    v-for="slot in schedule.slots"
                    :key="slot.id"
                    :slot="slot"
                    :active-slot="activeSlot"
                    :duration-steps="durationSteps"
                    @select="emit('select', slot)"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import ReservationSlotPickerAgendaColumnItem from '@aspect/ticket-office/components/reservation-slot-picker-agenda-column-item.vue';

    import type { Dayjs } from 'dayjs';
    import type { SlotData } from '@aspect/shared/types/generated';
    import type { SlotsBySchedule } from '@aspect/ticket-office/components/reservation-slot-picker-agenda.vue';

    defineProps<{
        schedule: SlotsBySchedule;
        activeSlot: SlotData | null;
        durationSteps: Dayjs[];
    }>();
    const emit = defineEmits<{
        select: [value: SlotData];
    }>();
</script>
