<template>
    <section
        :id="section.section_id"
        :class="['bg-white', classes.sectionPadding[globals.styles.spacing.value]]"
    >
        <div
            v-if="section.title"
            :class="[
                'container grid grid-cols-1',
                classes.contentGapHalf[globals.styles.spacing.value],
                classes.contentMarginBottomDouble[globals.styles.spacing.value],
            ]"
        >
            <h3 :class="['text-3xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.title }}
            </h3>
            <h4 v-if="section.sub_title" :class="['text-xl sm:text-2xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.sub_title }}
            </h4>
        </div>

        <div
            :class="[
                'container grid items-stretch',
                classes.layoutGrid[section.column_quantity.value],
                classes.contentGapDouble[globals.styles.spacing.value],
            ]"
        >
            <component
                :is="partner.link ? 'a' : 'div'"
                v-for="partner in section.partners_gallery"
                :key="partner.id"
                :href="partner.link"
                class="flex items-center justify-center"
                :rel="partner.link && 'external'"
                :target="partner.link && '_blank'"
            >
                <figure
                    :class="[
                        'grow',
                        classes.opticalAdjustement[partner.optical_adjustments.value],
                        classes.rounding[globals.styles.rounding.value],
                        section.frames && classes.imageFramed,
                    ]"
                >
                    <img
                        v-if="partner.logo"
                        :alt="partner.logo.alt"
                        :class="[
                            'size-full object-contain',
                        ]"
                        :height="partner.logo.height"
                        :src="partner.logo.url"
                        :width="partner.logo.width"
                    >
                </figure>
            </component>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();
</script>
