<!-- eslint-disable vue/no-v-html -->
<template>
    <figure
        v-if="svgContent"
        :class="[
            'aspect-icon flex items-center justify-center',
            filled && 'aspect-icon--fill',
        ]"
        v-html="svgContent"
    />
</template>

<script lang="ts" setup>
    import { computed, onMounted, shallowRef } from 'vue';
    import useCaching from '../composables/use-caching.ts';

    const props = withDefaults(defineProps<{
        name: string;
        filled?: boolean
    }>(), {
        filled: false,
    });

    const { get, set } = useCaching();

    const cacheKey = computed(() => `icons.${props.name}`);
    const svgContent = shallowRef<string|null>(null);
    const svgFiles: any = import.meta.glob('../icons/**/*.svg', { query: '?raw' });

    onMounted(async () => {
        if (!svgFiles[`../icons/${props.name}.svg`]) {
            console.error(`[AspectIcon] "${props.name}" does not exist`);
            return;
        }

        const cachedSvg = get(cacheKey.value);

        if (cachedSvg) {
            svgContent.value = cachedSvg;
            return;
        }

        try {
            const { default: svg } = await svgFiles[`../icons/${props.name}.svg`]();

            svgContent.value = svg;
            set(cacheKey.value, svg);
        } catch (error) {
            console.error(`[AspectIcon] ${error}`);
        }
    });
</script>

<style>
    .aspect-icon svg {
        height: 100%;
        width: 100%;
    }

    .aspect-icon.aspect-icon--fill,
    .aspect-icon.aspect-icon--fill * {
        fill: currentColor !important;
    }

    .aspect-icon.aspect-icon--stroke,
    .aspect-icon.aspect-icon--stroke * {
        stroke: currentColor !important;
    }
</style>
