import type { SlotData } from '@aspect/shared/types/generated';

// Helper function to calculate GCD of two numbers
function greatestCommonDivisor(a: number, b: number): number {
    if (b === 0) {
        return a;
    }
    return greatestCommonDivisor(b, a % b);
}

// Helper function to convert an ISO dateTime (2024-09-18T11:00:00+00:00) to minutes since midnight
function dateTimeToMinutes(dateTime: string): number {
    const date = new Date(dateTime); // Parse the ISO string into a Date object
    const hours = date.getUTCHours(); // Get the hour part (UTC)
    const minutes = date.getUTCMinutes(); // Get the minute part (UTC)
    return hours * 60 + minutes; // Convert hours to minutes and add the minutes
}

export function getDurationIncrement(slots: SlotData[]): number {
    if (!slots.length) {
        return 0;
    }

    // GCD of durations
    const durationGCD = slots
        .map(slot => slot.duration)
        .reduce((previous, current) => greatestCommonDivisor(previous, current));

    // Convert start times to minutes
    const startTimesInMinutes = slots.map(slot => dateTimeToMinutes(slot.dateTime));

    // Calculate time differences between consecutive start times
    const startTimeDiffs: number[] = [];

    for (let i = 1; i < startTimesInMinutes.length; i++) {
        startTimeDiffs.push(Math.abs(startTimesInMinutes[i] - startTimesInMinutes[i - 1]));
    }

    // GCD of start time differences (if there are differences)
    const timeGCD = startTimeDiffs.length > 0
        ? startTimeDiffs.reduce((previous, current) => greatestCommonDivisor(previous, current))
        : durationGCD; // Fallback to duration GCD if no start time differences

    // Final GCD is the GCD of both the duration GCD and the time GCD
    return greatestCommonDivisor(durationGCD, timeGCD);
}
