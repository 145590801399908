<template>
    <ReservationSlotPickerListItem
        v-for="slot in slots"
        :key="slot.id"
        :slot="slot"
        :active-slot="activeSlot"
        :max-location-width="maxLocationWidth"
        @select="emit('select', slot)"
    />
</template>

<script lang="ts" setup>
    import { computed } from 'vue';

    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

    import ReservationSlotPickerListItem from '@aspect/ticket-office/components/reservation-slot-picker-list-item.vue';

    import type { SlotData } from '@aspect/shared/types/generated';

    const props = defineProps<{
        slots: SlotData[];
        activeSlot: SlotData | null;
    }>();
    const emit = defineEmits<{
        select: [value: SlotData];
    }>();

    const pageProps = usePageProps();


    // MAX LOCATION WIDTH
    const maxLocationWidth = computed(() => {
        if (!pageProps.value.division.settings.ticketingLocationName) {
            return 0;
        }

        // Create a temporary element to measure text width
        const testElement = document.createElement('span');
        testElement.className = 'font-sans absolute invisible whitespace-nowrap text-sm';
        document.body.appendChild(testElement);

        let maxWidth = 0;

        // Loop through the locations and measure the width of each name
        props.slots.forEach((slot) => {
            testElement.textContent = slot.scheduleLocation?.name || '';
            const width = testElement.offsetWidth;

            if (width > maxWidth) {
                maxWidth = width;
            }
        });

        // Remove the temporary element
        document.body.removeChild(testElement);

        return maxWidth + 1;
    });
</script>
